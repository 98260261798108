.commonScrollBar {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $gray3;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollGray;
    border-radius: 12px;
  }
}

.commonScrollBarX {
  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $gray3;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollGray;
    border-radius: 12px;
  }
}
