.custom-hr {
  border-bottom: 1px solid #edf2f7;
}
.custom-red-hr {
  border-bottom: 1px solid #f1606380 !important;
  background: transparent !important;
}
.custom-border-top {
  border-top: 1px solid #edf2f7;
}
.custom-header {
  border-bottom: 1px solid #edf2f7;
  border-top: 1px solid #edf2f7;
}
.custom-modal-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: $black1;
}
.close-btn {
  color: $gray2;
  border: none;
  background: transparent;
}
.header-modal {
  border-bottom: 1px solid #edf2f7;
  padding: 14px;
}
.custom-modal-wrapper {
  background-color: #ffffff;
  height: 100%;
}
.custom-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $black1;
  padding-left: 4px;
  .label-red {
    color: $red1;
  }
}
.custom-table-input {
  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $neutrals5;
  }

  .MuiTablePagination-input {
    color: inherit;
    font-size: inherit;
    flex-shrink: 0;
    background: #f2f2f7;
    border-radius: 8px;
    min-height: 40px;
  }
  .MuiTablePagination-caption {
    font-size: 14px;
    line-height: 17px;
    color: #73788b;
  }
  .MuiToolbar-gutters {
    padding-left: 0;
  }
}

.picker {
  .custom-input {
    width: 100%;
    padding: 0 8px;
    border-radius: 24px;
    background: #f6f7f9;
    transition: border-color 0.2s;
    color: #17171b !important;
    border: 1px solid !important;
    border-color: #eceff2 !important;
    font-weight: 400;

    &:hover {
      border-color: #b1b5c3 !important;
    }
  }
}

.custom-input {
  background: $gray3;
  border-radius: 8px;
  min-height: 36px;

  .MuiInputBase-root,
  .MuiSelect-root {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &.MuiOutlinedInput-root {
    border-radius: 8px;
    height: 40px;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
  }
  .MuiOutlinedInput-input {
    padding: 12.5px 14px;
  }
  input::placeholder,
  textarea::placeholder {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px !important;
    color: $gray1;
  }
  .Select-control,
  .select__control,
  .css-1s2u09g-control {
    font-family: "Poppins", sans-serif !important;
    background: $gray3;
    border-radius: 8px;
    height: 36px;
    border: 0px;
    .Select-multi-value-wrapper {
      height: 36px;
      .Select-input {
        height: 36px;
        input {
          line-height: 28px;
        }
      }
      .Select-placeholder,
      .Select-value {
        line-height: 36px;
      }
    }
  }
  .css-yk16xz-control,
  .css-1pahdxg-control {
    font-family: "Poppins", sans-serif !important;
    background: $gray3;
    border-radius: 8px;
    border: none;
    height: 36px;
    box-shadow: none;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  &.custom-value-select .MuiInputBase-input {
    font-family: "Poppins", sans-serif !important;
    height: 36px;
    background-color: transparent;
    padding: 0;
  }
  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    display: none;
  }
  .MuiOutlinedInput-multiline {
    padding: 0;
  }
  .MuiIconButton-root {
    padding: 0;
  }
  // .WAMuiChipInput-inputRoot-57.WAMuiChipInput-outlined-60,
  // .WAMuiChipInput-inputRoot-91.WAMuiChipInput-outlined-94 {
  //   padding: 0;
  // }
}

.custom-sub-title {
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: $gray1;
}

.custom-fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: auto;
  z-index: 1030;
  border-radius: 0 0 0 15px;
}

.custom-bottom {
  position: absolute;
  left: 0;
  background-color: #fff;
}

.left-detail {
  width: 20px;
  height: 20px;
  display: block;
}

.iconSpan {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  margin-left: 5px;
}
.card {
  border-radius: 4px;
  border: none;

  //.subTitle {
  //  font-family: SF UI Text;
  //  font-weight: 500;
  //  font-size: 13px;
  //  line-height: 16px;
  //  color: $gray1;
  //  margin-top: 4px;
  //}
  &.card-areachart {
    padding: 0;
    overflow: hidden;
    .card-body {
      padding: 0;
      .chart-header {
        padding: 24px 24px 0;
      }
    }
  }
}

//topevent page
.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $blue1;
}
.chart-title {
  color: $gray1;
}
.default-table {
  tr {
    th,
    td {
      min-height: 48px;
    }
  }
}
.top-event-wrap {
  border-right: 1px solid #edf2f7;
  .default-table {
    tr {
      min-height: 48px;
      a,
      .price {
        color: $gray1;
        font-family: "Poppins", sans-serif !important;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
      a {
        font-weight: 500;
      }
      &.active {
        a,
        .price {
          color: $blue1;
        }
      }
    }
  }
}
.error-msg {
  font-size: 11px;
}
.error-icon {
  color: $red1;
  margin-right: 4px;
}

//reorder list page
.re-order-list {
  .table-unbordered .th-border-b th {
    border-bottom: none !important;
    line-height: 28px;
    background: #fafafb;
    padding: 10px 30px;
  }
  .table-unbordered td {
    padding: 10px 10px 10px 30px;
    border-bottom: 1px solid #edf2f7 !important;
    line-height: 28px;
  }
  .sub-title {
    color: $gray1;
  }
}

//event page
.event-page {
  .title {
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: $black1;
  }
  .event-alert {
    padding: 12px 24px;
    background: #fff;
    border-radius: 4px !important;
    min-height: 65px;
    border: 1px solid rgb(226, 232, 240);
    flex-wrap: wrap;
    row-gap: 14px;

    &-icon {
      background: #67cba026;
      border-radius: 10px;
      height: 46px;
      width: 46px;
      min-width: 46px;
    }
    &-msg {
      font-size: 14px;
      line-height: 17px;
      color: #27272e;
    }
    &-title {
      font-weight: 600;
      font-size: 16px;
      color: $black1;
    }
    &-subtitle {
      font-size: 14px;
      line-height: 16px;
      color: $gray1;
    }
    &.delivery-card-left {
      border-radius: 15px 0 0 15px;
    }
    &.delivery-card-right {
      border-radius: 0 15px 15px 0;
    }
  }
  .event-items {
    background: #fff;
    border-radius: 4px;
    padding: 6px 24px 24px 24px;
    &-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $black1;
    }
  }

  .status {
    position: absolute;
    background: red;
    padding: 0 10px;
    color: #ffffff;
    border-radius: 0 10px 10px 0;
    left: 0;
    top: 35px;
    &.pending {
      background: $red1;
    }
    &.complete {
      background: $green1;
    }
    &.winner {
      background: $orange1;
    }
  }
}
.report-table {
  &-header {
    background: #fafafb;
    .table-th {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $gray1;
    }
  }
  .table-td {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    &.blue {
      color: $blue1;
    }
    &.black {
      color: #27272e;
    }
    &.gray1 {
      color: $gray1;
    }
    &.gray2 {
      color: $gray2;
    }
    &.green1 {
      color: $green1;
    }
  }
  .border-bottom {
    border-bottom: 1px solid #edf2f7 !important;
  }
}

//multimedia page
.media-card {
  &-body {
    // height: 145px;
    background: rgba(76, 111, 255, 0.07);
    border: 1.5px dashed rgba(76, 111, 255, 0.6);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 16px;
    // overflow: hidden;
  }
  &-site-logo {
    width: 160px;
  }
  &-wrap {
    display: flex;
    overflow-x: hidden;
    flex-wrap: wrap;
    height: 100%;
    margin-right: -45px;
    flex: 1;
  }
  &-error-msg {
    font-size: 12px;
    line-height: 14px;
    color: $gray1;
  }
  &-img {
    height: 120px;
    width: 120px;
    background: #ffffff;
    border-radius: 8px;
    position: relative;
    img {
      border-radius: 8px;
      background-color: #e6e6e6;
      height: max-content !important;
      max-height: 100%;
    }
    .remove-icon {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      color: $red1;
      width: 38px;
      height: 26px;
      border-radius: 0 0 0 8px;
      background: #ffffff;
      svg {
        font-size: 18px;
      }
    }
  }
  .upload-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #27272e;
  }
  .blue {
    color: $blue1;
  }
  .media-card-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $black1;
  }
}

//ticketcell
.ticket-table {
  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $blue1;
  }
  .sub-name {
    font-size: 12px;
    line-height: 14px;
    color: $gray1;
  }
  .price-tag {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #27272e;
  }
  .price-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $green1;
  }
  .badge {
    font-weight: 500;
    font-size: 12px !important;
    line-height: 14px;
    padding: 5px 20px !important;
    border-radius: 8px;

    &-success {
      background: rgba($green1, 0.1);
      color: $green1;
      font-weight: 600;
    }
    &-danger {
      background: rgba($red1, 0.1);
      color: $red1;
      font-weight: 600;
    }
    &-blue {
      background: rgba($blue1, 0.1);
      color: $blue1;
      font-weight: 600;
    }
  }
}

.last-changed-tag {
  font-weight: 400 !important;
  font-size: 10px !important;
  text-transform: uppercase;
  line-height: 1 !important;
  color: #27272e;
}

//member reward
.member-reward-card {
  position: relative;
  .more-icon {
    position: absolute;
    right: 22px;
    top: 22px;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #27272e;
  }

  .description {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: $gray1;
  }
}

//leader board
.code-detail {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: $gray1;
  border-radius: 3rem;
  padding: 10px 24px;
  border: 1px dashed #3939ff4d;
  .tribe-code {
    font-weight: 600;
  }
}
.rank-card {
  background: $blue1;
  border-radius: 10px;
  color: #ffff;
  padding: 24px;
  &.green {
    background: $green1;
  }
  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
  }
  .sub-title {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }
}

.rank-box {
  border-top: 1px solid #edf2f7;
  border-bottom: 1px solid #edf2f7;
  &-right {
    border-right: 1px solid #edf2f7;
  }
}

//paid shipping
.paid-shipping {
  border: 1.5px solid #edf2f7;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 30px;
  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #27272e;
  }
  .sub-title {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $gray1;
  }
  &:hover {
    border-color: $blue1;
    cursor: pointer;
    .h7-btn-raised {
      color: white;
      &.blue {
        background-color: $blue1;
      }
      &.red {
        background-color: $red1;
      }
    }
  }
}

//radio label
.radio-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #27272e;
}
.radio-sub-label {
  font-size: 12px;
  line-height: 14px;
  color: #73788b;
}

//add insurance
.add-ins-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #27272e;
}
.add-ins-subtitle {
  font-size: 12px;
  line-height: 14px;
  color: #73788b;
}

.cursor-pointer {
  cursor: pointer;
}

.transaction-card {
  min-height: 65px;
}

.text-count {
  bottom: 10px;
  right: 10px;
}

//link
.h7-link {
  a {
    &:hover {
      color: #000000;
    }
  }
}

.h7-span-link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

//custom switch
.MuiFormControl-root .MuiSwitch-root {
  height: 26px;
  width: 42px;
  padding: 0;
  margin: 8px;
  .MuiSwitch-switchBase {
    color: #fff;
    padding: 1px;
    top: 50%;
    transition: none;
    transform: translateY(-50%);
  }
  .MuiSwitch-track {
    border: none;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 13px;
    background: $green1;
    opacity: 0.3;
  }
  .Mui-checked {
    .MuiSwitch-track {
      opacity: 1;
      background-color: $green1;
      transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    opacity: 1;
    background-color: $green1;
  }
  .MuiSwitch-switchBase.Mui-checked {
    transform: translate(16px, -50%);
  }
}

.custom-check-box {
  border: 1px solid #f2f2f7;
  border-radius: 8px;
  padding: 5px 30px 5px 5px;
}
.discount-header {
  min-width: 330px;
  border-right: 1px solid #edf2f7;
  width: 330px;
  display: block;
}

.campaign-details-header {
  min-width: 180px;
  border-right: 1px solid #edf2f7;
  width: 180px;
}

.blank-div {
  background: #f2f2f7;
  border-radius: 8px;
}
.dynamic-border-bottom {
  &:not(:last-child) {
    border-bottom: 1px solid #edf2f7;
  }
}

.custom-outline-box {
  border: 1.5px solid #edf2f7;
  border-radius: 10px;
  padding: 5px 15px;
}

.winner-badge {
  font-size: 12px;
  padding: 0 10px;
  color: #ffffff;
  border-radius: 10px;
  background: $orange1;
}

.DateInput {
  background-color: #f6f7f9 !important;
  border-radius: 48px !important;
}

.DateInput_input__focused {
  background-color: #f6f7f9 !important;
  border-radius: 48px !important;
  border: none !important;
}

.DateInput_input {
  background-color: #f6f7f9 !important;
  border-radius: 48px !important;
  font-size: 14px !important;
  color: #000 !important;
  font-weight: 300 !important;
}

.DateRangePickerInput_arrow {
  padding-inline: 10px !important;
}

.DateRangePickerInput__withBorder {
  border-radius: 10px !important;
  border: none !important;
}

.MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
}

.cursor-pointer {
  cursor: pointer;
}

.change-date-picker {
  .SingleDatePicker {
    width: 100%;
  }

  .SingleDatePickerInput__showClearDate {
    width: 100%;
  }

  .SingleDatePickerInput {
    background-color: $gray3;
  }

  .SingleDatePickerInput__withBorder {
    width: 100%;
    padding: 2px 8px;
    border-radius: 6px;
    background: none;
    transition: border-color 0.2s;
    color: #17171b !important;
    border: 1px solid #e6e8ec !important;
    background: #fcfcfd !important;
    font-weight: 400;
  }

  .DateInput__small {
    width: 70%;
  }

  .DateInput_input {
    background: #fff !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }

  input::placeholder,
  textarea::placeholder {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px !important;
    color: $gray1;
  }
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  border-right: 33px solid #3939ff !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #3939ff !important;
  border: 1px solid #3939ff !important;
  color: #fff;
}

.CalendarDay__selected_span {
  background: rgba($blue1, 0.5) !important;
  border: 1px solid #3939ff !important;
  color: #fff !important;
}

.CalendarDay__hovered_span {
  background: rgba($blue1, 0.5) !important;
  border: 1px solid #3939ff !important;
  color: #fff !important;
}

.date-range-picker {
  .DateRangePicker {
    width: 100%;
    // background-color: $gray3;
  }

  .DateRangePickerInput {
    background: transparent !important;
  }
  .DateRangePickerInput__withBorder {
    width: 100%;
    // background-color: $gray3;
  }

  .DateInput__small {
    width: 40% !important;
    min-height: 40px;
  }

  .DateInput_input {
    width: 100%;
    padding: 2px 8px;
    border-radius: 48px !important;
    background: none;
    transition: border-color 0.2s;
    color: #17171b !important;
    border: 1px solid !important;
    border-color: #eceff2 !important;
    background: #f6f7f9 !important;
    font-weight: 400;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    line-height: 16px !important;

    &:hover {
      border-color: #b1b5c3 !important;
    }
  }

  input::placeholder,
  textarea::placeholder {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px !important;
    color: $gray1;
  }

  .DateInput_input__small {
    padding: 11px !important;
  }
}

.mandatory-padding {
  padding: 0 0 0 20px !important;
}

.custom-input-select {
  background: $gray3;
  border-radius: 8px;
  min-height: 36px;

  .MuiInputBase-root,
  .MuiSelect-root {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    line-height: 16px !important;
    padding: 0 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &.MuiOutlinedInput-root {
    border-radius: 8px;
    height: 40px;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
  }
  .MuiOutlinedInput-input {
    padding: 12.5px 14px;
  }
  input::placeholder,
  textarea::placeholder {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px !important;
    color: $gray1;
  }
  .Select-control,
  .select__control {
    font-family: "Poppins", sans-serif !important;
    background: $gray3;
    border-radius: 8px;
    height: 36px;
    .Select-multi-value-wrapper {
      height: 36px;
      .Select-input {
        height: 36px;
        input {
          line-height: 28px;
        }
      }
      .Select-placeholder,
      .Select-value {
        line-height: 36px;
      }
    }
  }
  .css-yk16xz-control,
  .css-1pahdxg-control {
    font-family: "Poppins", sans-serif !important;
    background: $gray3;
    border-radius: 8px;
    border: none;
    height: 36px;
    box-shadow: none;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  &.custom-value-select .MuiInputBase-input {
    font-family: "Poppins", sans-serif !important;
    height: 36px;
    background-color: transparent;
    padding: 0;
  }
  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    display: none;
  }
  .MuiOutlinedInput-multiline {
    padding: 0;
  }
  .MuiIconButton-root {
    padding: 0;
  }
}

.remove-icon-container {
  position: relative;
  z-index: 10;
  .remove-icon {
    z-index: 20;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    color: $red1;
    width: 38px;
    height: 26px;
    border-radius: 0 0 0 8px;
    background: #ffffff;
    svg {
      font-size: 18px;
    }
  }
}

.image-width-100 {
  img {
    width: 100% !important;
  }
}

.remove-icon-white {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #f16063;
  width: 38px;
  height: 26px;
  border-radius: 0 0 0 8px;
  background: #ffffff;
  top: 0;
  right: 0;
  svg {
    font-size: 16px;
  }
}
