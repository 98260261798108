.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-7 {
  gap: 1.75rem;
}
.gap-8 {
  gap: 2rem;
}

.flex-auto {
  flex: 0 0 auto;
}

.szh-menu {
  padding: 8px !important;
  border-radius: 24px !important;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  border: 0.5px solid #e6e6e6 !important;

  .szh-menu__item {
    display: flex;
    align-items: center;
    padding: 0 6px !important;
    height: 40px;
    text-decoration: none;
    border-radius: 24px !important;
    font-size: 12px !important;
    border: 1px solid #fff;

    &:hover {
      background-color: #f6f7f9;
      border: 1px solid #e6e6e6;
    }
  }
}

.react-menu-menubutton {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.szh-menu-container {
  z-index: 999999;
}

.notistack-SnackbarContainer {
  z-index: 9999999 !important;
  word-break: break-all;
}
