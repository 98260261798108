$eventNavy: #3e2b59;
$eventOrange: #64e0f6;
$eventLightOrange: #fef0ed;

.merlin {
  &-container {
    width: 100%;
    max-width: 600px;
    position: relative;
  }
  &-image-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
  }
  &-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    gap: 1rem;
    // background: linear-gradient(135deg, #73b4f1, #a3e6f1);
    overflow: auto;
    width: 100%;
    max-width: 80%;
    border-radius: 5px;
  }
  &-image {
    width: 100%;
    max-width: 60%;
  }
  &-title {
    position: absolute;
    top: 61px;
    color: #2dfffe;
    left: 22px;
    font-size: 24px;
    font-weight: 600;
    z-index: 1;
  }
  &-sub-title {
    position: absolute;
    top: 96px;
    color: #2dfffe;
    font-size: 12px;
    font-weight: 400;
    z-index: 1;
    left: 22px;
    width: 190px;
    font-style: italic;
  }
  &-button-container {
    position: absolute;
    top: 175px;
    border: 2px solid #2dfffe;
    font-size: 16px;
    font-weight: 500;
    z-index: 1;
    left: 22px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px;
    border-radius: 20px;
  }
  &-button-container-responsive {
    border: 2px solid #3939ff;
    font-size: 16px;
    font-weight: 500;
    z-index: 1;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px;
    border-radius: 20px;
  }
  &-button {
    background: #2dfffe;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    left: 22px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 15px;
    text-transform: uppercase;
  }
  &-button-responsive {
    background: #3939ff;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    left: 22px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 15px;
    text-transform: capitalize;
  }
  &-robot-wrapper {
    height: 200px;
  }
  &-category {
    border: 1px solid #dee2e6;
    padding: 0.5rem;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: linear-gradient(135deg, #73b4f1, #a3e6f1);
      color: #fff;
    }
  }
  &-category-active {
    border: 1px solid #dee2e6;
    padding: 0.5rem;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(135deg, #73b4f1, #a3e6f1);
    color: #fff;
  }
  &-description-box-outer {
    position: relative;
    overflow: hidden;
  }

  &-description-box-container {
    position: relative;
    resize: vertical;
    overflow: auto;
    min-height: 150px;
  }
  &-result-container {
    width: 33%;
    border-radius: 10px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    max-height: 150px;
    transition: all 0.5s;
  }
  &-expanded {
    max-height: 800px;
  }
  &-odd-color {
    background: #c4f3ed;
  }
  &-even-color {
    background: #ffe6b7;
  }
  &-result-title {
    background: white;
    padding: 2px 8px;
    border-radius: 13px;
  }
  &-regenerate-button {
    padding: 3px;
    background: #000;
    border-radius: 50%;
    color: #fff !important;
  }
  &-use-this-button {
    padding: 3px;
    background: #000;
    border-radius: 50%;
    color: #fff !important;
  }
}

@keyframes robotMoves {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(10px);
  }
}

@keyframes gearhSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes eyesBlink {
  10% {
    transform: scaleY(1);
  }
  11% {
    transform: scaleY(0);
  }
  12% {
    transform: scaleY(0);
  }
  13% {
    transform: scaleY(1);
  }
}

@keyframes eyesMoves {
  10% {
    transform: translateX(0px);
  }
  13% {
    transform: translateX(-16px);
  }
  30% {
    transform: translateX(-16px);
  }
  33% {
    transform: translateX(16px);
  }
  45% {
    transform: translateX(16px);
  }
  47% {
    transform: translateX(0px);
  }
}

@keyframes antennaMoves {
  10% {
    transform: translateX(0px);
  }
  13% {
    transform: translateX(2px);
  }
  30% {
    transform: translateX(2px);
  }
  33% {
    transform: translateX(-20px);
  }
  45% {
    transform: translateX(-20px);
  }
  47% {
    transform: translateX(0px);
  }
}

.robot {
  animation: robotMoves 2s ease-in-out alternate infinite;
}

.robot-arm-left,
.robot-arm-right,
.gear,
.gear-1,
.robot-antenna {
  pointer-events: none;
}

.gear {
  transform-box: fill-box;
  transform-origin: center center;
  animation: gearhSpin 3s linear infinite;
}
.gear-1 {
  display: none;
  transform-box: fill-box;
  transform-origin: center center;
  transform: translate(-20px, 78px);
}

.robot-eyes > circle {
  transform-box: fill-box;
  transform-origin: center center;
  animation: eyesBlink 2s linear alternate infinite;
}

.robot:hover .robot-eyes {
  transform-box: fill-box;
  transform-origin: center center;
  animation: eyesMoves 5s linear alternate infinite;
}

.robot:hover .robot-antenna {
  animation: antennaMoves 5s linear alternate infinite;
}

.robot:hover .gear {
  display: none;
}
.robot:hover .gear-1 {
  display: inline;
}

.robot:hover .robot-arm-right {
  transform-box: fill-box;
  transform-origin: center center;
  transform: rotateY(180deg) rotate(165deg) translate(3px, -35px);
}

.robot:hover {
  animation-play-state: paused;
}

.scroll {
  &-common {
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #3939ff;
    }
  }
}

$colors: #fc2785, #59c0fc, #6068c2;
$total-colors: length($colors);
$animation-duration: 2s;
$colorsArray: #faf4e3, #f2dbd5, #d9b2a9, #f4eeed, #f2e4d8, #f2ebe9, #d9d6d2, #e6e6e6, #edddbc, #eb7428, #9cbcb7, #e89275, #cbdba7;

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0; /* Background color of the progress bar container */
  border-radius: 15px;
  overflow: hidden;
}

.progress {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, $colors);
  background-size: 400% 100%; /* Adjusting background size for animation */
  animation: progress-animation $animation-duration linear infinite;
}

@keyframes progress-animation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@for $i from 1 through $total-colors {
  .progress-bar:nth-child(#{$i}) .progress {
    background-position: (100% / $total-colors * ($i - 1)) 50%;
    animation-delay: -$animation-duration + ($animation-duration / $total-colors * ($i - 1));
  }
}

.magic-wand {
  &-container {
    padding: 10px 20px;
    border: 1px solid #e6e8ec;
    border-radius: 10px;
  }
  &-color {
    color: #fc2785;
  }
  &-robot-color {
    color: #3cc5f3;
  }
}

.w-66 {
  width: 66% !important;
}

.bounce2 {
  animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  // 40% {
  //   transform: translateY(-10px);
  // }
  60% {
    transform: translateY(-5px);
  }
}

.merlin-card {
  width: 300px;
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease; /* Animation for height and padding transition */
  max-height: 200px; /* Default height */
  margin-bottom: 20px; /* Add margin-bottom to create space between cards */
}

.merlin-card.expanded {
  max-height: none; /* Set max-height to none when expanded */
  padding: 20px; /* Increase padding when expanded */
}

.merlin-card-header {
  background-color: #ddd;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.merlin-card-content {
  padding: 10px;
}

.merlin-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 20px;
  color: #555; /* Adjust color as needed */
  transition: color 0.3s ease; /* Transition for color change */
}

.merlin-toggle-button:hover {
  color: #000;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.up-down-button {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
