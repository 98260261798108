/*Icons Styles*/

.icon {
  display: inline-block;
  @extend %size-30;
  text-align: center;
}

.icons-wrapper {
  display: inline-block;
  & + .icons-wrapper {
    margin-left: 25px;
  }
}

.icon-addon {
  @extend %icon-addon;
}

$colorArray: (
  "green": #66cb9f,
  "blue": #3939ff,
  "yellow": #fdbe43,
  "red": #f16063,
  "orange": #f7936f,
);

.icon-box {
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 10px;
  margin-right: 15px;
  @each $name, $color in $colorArray {
    &-#{$name} {
      color: #{$color};
      background: rgba($color, 0.1);
    }
  }
}

.insight-icon-box {
  height: 30px;
  width: 30px;
  border-radius: 10px;
  @each $name, $color in $colorArray {
    &-#{$name} {
      color: #{$color};
      background: rgba($color, 0.1);
    }
  }
}

.icon-rounded {
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @each $name, $color in $colorArray {
    &-#{$name} {
      color: white;
      background: rgba($color, 1);
    }
  }
}

.star-icon {
  position: absolute;
  right: 22px;
  top: 26px;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#ffffff, 0.4);
  cursor: pointer;

  &.fill {
    background: #ffffff;
    color: #fdbe43;
  }
}
