.linkClassPagination {
  color: #73788b;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  &:hover {
    color: #3939ff;
    text-decoration: underline;
  }
}

.previousNextClassPagination {
  color: #3939ff;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  &:hover {
    color: #3939ff;
    text-decoration: underline;
  }
}

.disabledClassPagination {
  color: rgba(#73788b, 0.5);
  cursor: not-allowed;
  &:hover {
    color: rgba(#73788b, 0.5);
    text-decoration: none;
  }
}

.pageLinkClassPagination {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #343b46 !important;
  border: 1px solid #eceff2;
  background-color: #ffffff;
  &:hover {
    background-color: #3386dd;
    color: #ffffff !important;
    border: 1px solid #3386dd;
    // border-radius: 4px;
    text-decoration: none;
    // background: #fff;
  }
}

.activeClassPagination {
  color: #ffffff !important;
  font-weight: 600 !important;
  border: 1px solid #3386dd;
  // border-radius: 50;
  // text-decoration: none;
  background: #3386dd;
}

.list-style-none {
  list-style-type: none;
}

.w-35 {
  width: 32px;
}

.pagination-select {
  width: 100%;
  .css-1d6no1b-control {
    border-radius: 25px !important;
  }
  input[aria-readonly="true"] {
    width: 0;
  }
}

.max-w-fit {
  max-width: fit-content;
}

.-w-fit {
  width: fit-content !important;
}

.no-wrap {
  text-wrap: nowrap;
}
