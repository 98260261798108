@import "../../styles/global/_color.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 2.5px solid;
  border-bottom-color: rgba($neutrals5, 0.8);
  -webkit-animation: loader 0.7s infinite linear;
  animation: loader 0.7s infinite linear;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
