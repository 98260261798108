$black: #23262f;
$white: #ffffff;

.sideBar {
  height: 100dvh;
  width: 274px !important;
  background-color: $white !important;
  color: $black !important;
  border-right: 1px solid #eceff2 !important;
  z-index: 1250 !important;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  // padding: 24px;
  gap: 32px;
}

.closeIcon {
  position: absolute;
  top: 3rem;
  right: -18px;
  color: #777e90;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgb(51 51 51 / 30%);
}
