$neutrals3: #353945;
$neutrals4: #777e90;
$primary2: #00a1e1; // Official Light Blue

.menuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 10px;
  cursor: pointer;
  color: #677990;
  border-radius: 16px;
  position: relative;
  margin-inline: 24px;

  &:hover {
    color: #3386dd;
    background-color: #f0f8fe;
  }

  &.selectedMenuItem {
    color: #3386dd !important;
    background-color: #f0f8fe !important;
  }

  &.subMenuItem {
    padding-left: 2rem;
  }

  &.nestedSubMenuItem {
    padding-left: 3rem;
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.iconTitleWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;
  overflow: hidden;

  .icon {
    margin: 5px 0;
    flex-shrink: 0;
  }

  .title {
    font-size: 1rem;
    font-weight: 500;
    white-space: nowrap;
  }
}

.expandIcon {
  transition: all 0.4s;

  &.show {
    transform: rotate(90deg);
  }
}

.sideRect {
  position: absolute;
  height: 110%;
  width: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #3386dd;
  left: -24px;
}
