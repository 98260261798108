

  body {
    font-family: 'Poppins', sans-serif;
  }

  body.fontLoaded {
    font-family: 'Poppins', sans-serif;
  }

  #app {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: 'Poppins', sans-serif;
    line-height: 1.5em;
  }
  .header-container {
    padding:5px 0 5px 0;
    margin-bottom:0;
  }

  .content-container {
    display: inline-flex;
    justify-content: space-between;
    margin: 0 auto;
    width:80%;
    @media (max-width: 992px) {
      width: 100%;
      margin: 0 10px 0 10px;
    }
  }

  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

  .clickableSection {
    background-color:#C1F0F6;
    fill:#C1F0F6;
    color:#FFFFFF;
    cursor:default;
  }

  .clickableSection:hover {
    background-color:#63D1F4;
    fill:#7EC0EE;
    cursor:pointer;
  }

  .unclickableSection {
    background-color:#A0A0A0;
    fill:#A0A0A0;
    cursor:not-allowed;
  }

  .unclickableSection:hover {
    background-color:#A0A0A0;
    fill:#A0A0A0;
    cursor:not-allowed;
  }

  .selectedSeat {
    fill:#00BFFF;
    background-color: #00BFFF;
  }

  .selectableSeat {
    fill:#EBEBEB;
    background-color:#EBEBEB;
  }

