/*Drawer Styles*/

.drawer {
  width: 250px;
}

.venues-drawer {
  width: 750px;

  @media screen and (max-width: 575px) {
    width: 350px !important;
  }
}

.media-drawer {
  width: 700px;

  @media screen and (max-width: 750px) {
    width: 380px !important;
  }
}

.img-height {
  height: 180px;
}

.full-drawer {
  width: 100%;
}

.img-thumbnail {
  border: 1px solid $gray-500;
  border-radius: 4px;
  padding: 5px;

  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}

.thumbnail-width {
  width: 150px;
  height: 150px;
}

.horizontal-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  div {
    flex: 0 0 auto;
  }
}

.drawer-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  background-color: #fff;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}
