/* Calendar Module Style */

.rbc-calendar {
  .app-calendar & {
    @include display-flex(flex, column, nowrap);
    @include flex(1);
    min-height: calc(100vh - 160px);
  }

  .app-cul-calendar & {
    min-height: calc(100vh - 200px);
  }
}

.rbc-event {
  background-color: $app-primary;
}

.rbc-event.rbc-selected {
  background-color: darken($app-primary, 10%);
}

.rbc-slot-selection {
  background-color: rgba($app-primary, 0.7);
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: $white;
  background-image: none;
  @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
  //box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: rgba($app-primary, 0.9);
  border-color: $app-primary;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: $white;
  background-color: rgba($app-primary, 0.9);
  border-color: $app-primary;
}

.rbc-toolbar button:focus {
  color: $white;
  background-color: rgba($app-primary, 0.9);
  border-color: $app-primary;
}

.rbc-toolbar button:hover {
  color: $white;
  background-color: rgba($app-primary, 0.9);
  border-color: $app-primary;
}

.rbc-month-row {
  min-height: 100px;
}

.rbc-event {
  min-height: 50px;
}

@media screen and (max-width: 867px) {
  .rbc-toolbar {
    @include display-flex(flex, column, nowrap);
    @include align-items(flex-start);
  }

  .rbc-toolbar .rbc-toolbar-label {
    margin: 8px 0;
  }
}

@media screen and (max-height: 600px) {
  .rbc-calendar {
    .app-calendar & {
      min-height: 400px;
    }

    .app-cul-calendar & {
      min-height: 400px;
    }
  }
}

@media screen and (max-width: 467px) {
  .rbc-toolbar {
    font-size: 12px;
  }

  .rbc-toolbar button {
    padding: 5px 10px;
  }
}

.box-office-recurring {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .box-office-date-picker {
    .SingleDatePickerInput {
      border-radius: 100px !important;
    }
    .SingleDatePicker_picker {
      .CalendarMonthGrid {
        width: fit-content !important;
      }
    }
    .DateInput {
      width: 15rem;
      input {
        background-color: white !important;
      }
    }
  }

  .box-office-showtime-list {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    flex-wrap: wrap;
  }

  .box-office-showtime-item {
    border: 1px solid $blue1;
    padding: 0.5rem 2rem;
    border-radius: 1.5rem;
    background-color: transparent;
    color: $blue1;
    font-weight: 500;
    cursor: pointer;

    &-selected {
      background-color: $blue1;
      color: $white;
    }
  }
}
