.videoItem {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 300px;
  position: relative;
  padding: 15px;
  border-radius: 15px;
  border: 1.5px solid #f2f2f7;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.06);

  .videoImage {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: 8px;
  }
}
