$paymentBlue: #4d6df0;

.pp {
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    background-color: rgba($paymentBlue, 0.05);
    overflow: auto;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    &-title {
      font-size: 2.813rem;
      font-family: Ubuntu;
      font-weight: 700;
    }

    &-close {
      border: 1px solid;
      padding: 4px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  &-contentWrapper {
    width: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 16px;
    box-shadow: 0 0 4px rgba($paymentBlue, 0.5);
    overflow: hidden;

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: $neutrals6;
      border-radius: 16px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #4e376b;
      border-radius: 16px;
    }
  }

  &-sidebar {
    padding: 16px;
    position: relative;
    background-color: $paymentBlue;
    border-radius: 8px;
    overflow: hidden;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #4e376b;
    }

    &-stepWrapper {
      transition: all 0.8s ease;
    }

    &-step {
      display: flex;
      align-items: center;
      color: #ffffff;
      gap: 16px;
      padding: 8px;
      transition: all 0.8s ease;
      &.hide-step {
        opacity: 0;
        pointer-events: none;
      }

      &.active-step {
        .pp-sidebar-step-number {
          background-color: #d6c8ff;
        }
      }

      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        border: 2px solid;
        border-radius: 16px;
        font-weight: 500;
      }
      &-content {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        line-height: 1.1;
        font-size: 0.875rem;
        font-weight: 500;
      }
      &-stepNumber {
        opacity: 0.7;
      }
    }

    &-wave {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
    }

    &-gradient {
      position: absolute;
      background: linear-gradient(0deg, #d6c8ff, #35c9fa, $paymentBlue, transparent);
      bottom: 0;
      left: 0;
      right: 0;
      height: 55vh;
    }
  }

  &-carousel {
    display: flex;
    width: 100%;
    height: 75vh;
    overflow: hidden;

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      transition: all 1s ease 0s;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 75vh;
    flex-shrink: 0;
    margin-bottom: 20vh;

    &-header {
      display: flex;
      flex-direction: column;
      color: $navy;
      margin-bottom: 32px;
      padding: 0 2px;

      &-title {
        font-size: 2rem;
        font-weight: 700;
        font-family: "Ubuntu";
        line-height: 1.25;
      }

      &-info {
        font-size: 0.875rem;
        font-weight: 600;
        color: $gray1;
      }
    }

    &-form {
      display: flex;
      width: 100%;
      flex-grow: 1;
      overflow-y: auto;
      padding: 0 2px;
    }

    &-footer {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 16px;
      padding: 12px 12px 0 2px;
    }
  }

  &-footer-btn {
    border: 0;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: $navy;
    color: #ffffff;
    @media (min-width: 640px) {
      padding: 8px 32px;
    }
  }

  &-save-btn {
    border: 0;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: $paymentBlue;
    color: #ffffff;
    @media (min-width: 640px) {
      padding: 8px 32px;
    }
  }

  &-text-btn {
    border: 0;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: transparent;
    color: $navy;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 8px 2rem;
    }
  }

  &-outline-btn {
    border: 1px solid $navy;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: transparent;
    color: $navy;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 8px 2rem;
    }
  }

  &-input {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-label {
      font-size: 0.875rem;
      font-weight: 600;
      color: $navy;
      margin-bottom: 4px;

      &-big {
        font-size: 1.063rem;
        font-weight: 600;
        color: $navy;
        margin-bottom: 2px;
      }

      &-small {
        font-size: 0.75rem;
        font-weight: 600;
        color: $navy;
        margin-bottom: 2px;
      }
    }

    &-error {
      border: 1px solid rgba(#b0222b, 0.5) !important;
      background-color: rgba(#b0222b, 0.05) !important;
    }

    &-input {
      margin: 0;
      padding: 12px;
      border: 1px solid rgba($gray1, 0.5);
      border-radius: 8px;
      outline: none;
    }
    &-helper {
      font-size: 0.875rem;
      font-weight: 500;
      color: $gray1;
    }
  }

  &-customRadio {
    width: 220px;
    padding: 7px 20px !important;
    border-radius: 8px;
    gap: 6px !important;
    flex-direction: row-reverse;
    font-weight: 700 !important;
    margin: 0 !important;
    justify-content: center;

    &-active {
      background-color: rgba($paymentBlue, 0.07) !important;
      border: 2px solid $paymentBlue !important;
    }
    &-inactive {
      background-color: $gray4 !important;
      border: 2px solid transparent !important;
    }
    &-disable {
      background-color: $gray4 !important;
      border: 1px solid $gray2 !important;
      cursor: not-allowed !important;
      opacity: 0.5;
    }
    &-option {
      color: $navy;
    }
  }

  &-customCheckbox {
    display: flex;
    width: 260px;
    padding: 2px 20px !important;
    border-radius: 8px;
    gap: 6px !important;
    flex-direction: row-reverse;
    font-weight: 700 !important;
    margin: 0 !important;
    justify-content: center;
    cursor: pointer;
    border: 2px solid transparent;

    &-active {
      background-color: rgba($paymentBlue, 0.07);
      border: 2px solid $paymentBlue;
    }
    &-inactive {
      background-color: $gray4;
    }
    &-option {
      color: $navy;
    }
  }

  &-thankYou {
    &-footer {
      display: grid;
      justify-content: center;
      align-items: center;
      align-self: center;
      gap: 16px;
      max-width: 768px;
      margin-bottom: 16px;
    }
    &-icon {
      font-size: 5rem !important;
      color: $paymentBlue;
      margin-bottom: 32px;
    }
  }
}
