.custom-dialog {
  z-index: 9999999 !important;
  .MuiDialog-paper {
    border-radius: 15px;
    .title-bold-18 {
      border: 1px solid #edf2f7;
      h2 {
        color: $black1;

        font-size: 18px;
        font-weight: bold;
        line-height: 21px;
      }
    }
    .title-18 {
      p {
        color: $gray1;

        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        margin-top: 22px;
      }
    }
    .custom-actions {
      .h7-btn {
        &.cancel-btn {
          &:hover {
            background-color: $red1 !important;
            color: white;
          }
        }
      }
    }
  }
}

.title-bold-16 {
  color: $black1;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
}
