.two-week-calendar {
  font-family: "Poppins", sans-serif;
  width: 100%;
  margin: 0 auto;

  &-title {
    margin: 0;
    font-weight: 700;
    font-family: "Ubuntu";
    color: #22272f;
    text-align: center;
  }
  &-main-header {
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    margin-bottom: 12px;
    font-size: 20px;
    color: #22272f;

    &-navigate {
      cursor: pointer;
      font-size: 24px;
      user-select: none;

      &-disabled {
        color: #bdbdbd;
        pointer-events: none;
      }
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  &-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border: 1px solid #ddd;
    border-radius: 12px;
  }
  &-box {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    min-width: 160px;

    &:nth-child(7n) {
      // Remove right border from the last day cell in each row
      border-right: none;
    }
    &:last-child:nth-last-child(1) {
      border-bottom: none;
    }
    &:nth-last-child(-n + 7) {
      border-bottom: none;
    }

    &-day {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding: 10px;

      &-header {
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        color: #052b5c;
        padding: 6px 3px;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-height: 0;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;

        &:last-child {
          border-right: none;
        }
      }
      &-number {
        text-align: right;
        font-weight: 500;
        margin-bottom: 5px;
        color: #052b5c;
      }
      &-events {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        min-height: 190px;
        gap: 5px;
      }
      &-event {
        background-color: #fafafa;
        padding: 2px 5px;
        margin-bottom: 3px;
        border-radius: 5px;
      }
      &-disabled {
        background-color: rgb(242, 243, 245);
      }
    }
    &-today {
      background-color: #e3f2fd;
    }
  }
}
