/*Label Styles*/
label {
  margin-bottom: 0;
}

label.shrink {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}

