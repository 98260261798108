$eventNavy: #3e2b59;
$eventOrange: #f68b64;
$eventLightOrange: #fef0ed;

.evs {
  &-wrapper {
    display: flex;
    flex-direction: column;
    // padding: 1rem 2rem;
    gap: 1rem;
    height: 100%;
    background: linear-gradient(135deg, #f9d4b7, #e7bee0);
    overflow: auto;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &-title {
      font-size: 45px;
      font-family: Ubuntu;
      font-weight: 700;
      color: $eventNavy;
    }

    &-close {
      border: 1px solid;
      padding: 4px;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }

  &-contentWrapper {
    // display: flex;
    // flex-direction: row;
    // gap: 5rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 0 4px rgba($blue1, 0.5);
    // min-height: 80vh;
    overflow: hidden;
    position: relative;

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: $neutrals6;
      border-radius: 16px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #4e376b;
      border-radius: 16px;
    }
  }

  &-sidebar {
    // display: flex;
    // flex-direction: column;
    padding: 1rem;
    position: relative;
    // width: 100%;
    background-color: $eventNavy;
    // max-width: 240px;
    border-radius: 8px;
    overflow: hidden;

    &-stepWrapper {
      // display: flex;
      // flex-direction: column;
      transition: all 0.8s ease;
    }

    &-stepWrapper-createFlow {
      height: auto;
      overflow-y: auto;
    }

    &-stepWrapper-editFlow {
      height: 260px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
      }
    }

    &-step {
      display: flex;
      align-items: center;
      color: #ffffff;
      gap: 1rem;
      padding: 8px;
      transition: all 0.8s ease;
      &.hide-step {
        opacity: 0;
        pointer-events: none;
      }

      &.active-step {
        .evs-sidebar-step-number {
          background-color: $eventOrange;
        }
      }

      &.edit-step {
        cursor: pointer;
        border-radius: 0.5rem;

        &:hover {
          background-color: rgba(#fff, 0.2);
        }
      }

      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        border: 2px solid;
        border-radius: 1rem;
        font-weight: 500;
      }
      &-content {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        line-height: 1.1;
        font-size: 14px;
        font-weight: 500;
      }
      &-stepNumber {
        opacity: 0.7;
      }
    }

    &-seen {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
    }

    &-flag {
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;
      height: 108px;
    }

    &-flag-2 {
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;
      transform: rotateY(180deg);
      height: 54px;
    }

    &-gradient-top {
      position: absolute;
      background: linear-gradient(0deg, $eventNavy 47.8%, transparent);
      bottom: 99px;
      left: 0;
      right: 0;
    }
    &-gradient-bottom {
      position: absolute;
      background: linear-gradient(180deg, #bd608c 20.9%, #442e81 86%);
      bottom: 0;
      left: 0;
      right: 0;
      height: 99px;
    }
  }

  &-carousel {
    display: flex;
    width: 100%;
    height: 75vh;
    overflow: hidden;

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 4px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 75vh;
    flex-shrink: 0;
    margin-bottom: 20vh;

    &-header {
      display: flex;
      flex-direction: column;
      color: $navy;
      margin-bottom: 2rem;

      &-title {
        font-size: 32px;
        font-weight: 700;
        font-family: "Ubuntu";
        line-height: 1.25;
      }

      &-info {
        font-size: 14px;
        font-weight: 600;
        color: $gray1;
        white-space: break-spaces;
      }
    }

    &-form {
      display: flex;
      width: 100%;
      flex-grow: 1;
      overflow-y: auto;
      padding: 0 8px;
    }

    &-footer {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      padding: 0.75rem 0.75rem 0 0;
    }
  }

  &-footer-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: $eventOrange;
    color: #ffffff;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-purple-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #4e376b;
    color: #ffffff;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-text-btn {
    border: 0;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    background-color: transparent;
    color: $navy;
    font-weight: 500;
  }

  &-outline-btn {
    border: 1px solid $eventNavy;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    background-color: transparent;
    color: $navy;
    font-weight: 500;
  }

  &-underline-hover {
    cursor: default;
    &:hover {
      text-decoration: underline;
    }
  }

  &-input {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-label {
      font-size: 14px;
      font-weight: 600;
      color: $navy;
      margin-bottom: 4px;

      &-big {
        font-size: 17px;
        font-weight: 600;
        color: $navy;
        margin-bottom: 2px;
      }

      &-extra-big {
        font-size: 20px;
        font-weight: 600;
        color: $navy;
        margin-bottom: 2px;
      }
    }

    &-error {
      border: 1px solid rgba(#b0222b, 0.5) !important;
      background-color: rgba(#b0222b, 0.05) !important;
    }

    &-input {
      margin: 0;
      padding: 12px;
      border: 1px solid rgba($gray1, 0.5);
      border-radius: 8px;
      outline: none;
    }
    &-helper {
      font-size: 14px;
      font-weight: 500;
      color: $gray1;
    }

    &-dateTime {
      background-color: #ffffff !important;
      border: 1px solid rgba($gray1, 0.5) !important;
    }
    &-link {
      font-size: 13px;
      font-weight: 600;
      color: $eventNavy;
      text-decoration: underline;
      flex-shrink: 0;
    }
  }

  &-customRadio {
    width: 220px;
    padding: 7px 20px !important;
    border-radius: 8px;
    gap: 6px !important;
    flex-direction: row-reverse;
    font-weight: 700 !important;
    margin: 0 !important;
    justify-content: center;

    &-active {
      background-color: rgba($blue1, 0.07) !important;
      border: 2px solid $navy !important;
    }
    &-inactive {
      background-color: $gray4 !important;
      border: 2px solid transparent !important;
    }
    &-disable {
      background-color: $gray4 !important;
      border: 1px solid $gray2 !important;
      cursor: not-allowed !important;
      opacity: 0.5;
    }
    &-option {
      color: $pink;
    }
  }

  &-eventInfo {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 540px;
  }

  &-eventDescription {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    &-terms-and-condition-container {
      display: flex;
      width: 100%;
      max-width: 640px;
      gap: 0.5rem;
      align-items: center;
    }
  }
  &-eventTermsAndCondition {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    max-width: 540px;
    margin-inline: 1.9rem;
  }

  &-eventPhotos {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 640px;

    &-div {
      display: flex;
      flex-direction: column;
    }
    &-imgPreview {
      position: relative;
      height: 100px;
      border-radius: 4px;
      overflow: hidden;

      &-delete {
        position: absolute;
        color: $eventOrange;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          opacity: 1;
        }
      }
    }
    &-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
      border: 1px dashed rgba($gray1, 0.5);
      border-radius: 0.5rem;
      background-color: $eventLightOrange;
      text-decoration: underline;
      cursor: pointer;

      &-upload {
        color: $eventOrange;
      }
    }

    &-imgList {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 0.5rem;
      padding: 0.5rem;
      border: 1px dashed rgba($gray1, 0.5);
      border-radius: 0.5rem;
      background-color: $eventLightOrange;
    }
  }

  &-eventCurrency {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 540px;
  }

  &-eventVenue {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 796px;

    &-or {
      width: 100%;
      margin: 0.8rem 0;
      text-align: center;
      position: relative;

      &::after,
      &::before {
        content: "";
        display: block;
        width: 40%;
        height: 1px;
        position: absolute;
        background: rgba($gray1, 0.5);
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        left: 0;
      }
      &::before {
        right: 0;
      }
    }

    &-venueType {
      display: flex;
      gap: 12px;

      &-info {
        display: flex;
        align-items: flex-start;
        gap: 8px;
      }

      &-details {
        display: flex;
        flex-direction: column;
        gap: 1px;
      }
      &-revealDate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2px;
        margin-top: 8px;

        .custom-input {
          background-color: #ffffff !important;
          border: 1px solid rgba($gray1, 0.5) !important;
        }
      }
    }
  }

  &-eventTiming {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    width: 100%;
    // max-width: 768px;

    &-timing {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;
      justify-content: space-between;
    }

    &-show {
      display: flex !important;
    }

    &-current {
      top: 25%;
      opacity: 1 !important;

      .evs-eventTiming-content {
        position: absolute;
        top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      }

      .evs-eventTiming-question {
        font-size: 20px;
      }
    }

    &-next {
      top: 85%;
    }

    &-prev {
      top: 0;
    }

    &-question {
      font-size: 14px;
      font-weight: 700;
      color: #052b5c;
      margin-bottom: 2px;
      line-height: 1.25;
      text-align: center;
      transition: all 0.8s ease;
    }
  }

  &-eventType {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 768px;
    gap: 1.25rem;

    &-icons {
      display: flex;
      margin-top: 1rem;

      button:hover {
        text-decoration: underline;
      }
    }

    &-show {
      display: flex !important;
    }

    &-current {
      top: 25%;
      opacity: 1 !important;

      .evs-eventType-question {
        font-size: 20px;
      }

      .evs-eventType-question-group {
        position: absolute;
        top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      }
    }

    &-next {
      top: 85%;
    }

    &-prev {
      top: 0;
    }

    &-input {
      display: grid;
      align-items: center;
      gap: 1rem;
      width: 100%;
      grid-template-columns: 1fr;
      @media (min-width: 640px) {
        grid-template-columns: 1fr 7rem;
      }
    }

    &-question {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 18px;
      font-weight: 700;
      color: #052b5c;
      margin-bottom: 2px;
      line-height: 1.25;

      &-group {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      &-selection {
        font-size: 14px;
        font-weight: 500;
      }
    }

    &-helper {
      font-size: 13px;
      font-weight: 500;
      color: #73788b;
      line-height: 1.25;
    }

    &-btn {
      border: 1px solid;
      padding: 0.25rem 2rem;
      border-radius: 0.5rem;
      font-weight: 500;
    }

    &-btn-notSelected {
      border-color: #3e2b59;
      background-color: transparent;
      color: #052b5c;
    }

    &-btn-selected {
      border-color: transparent;
      background-color: #4e376b;
      color: #ffffff;
    }
  }

  &-eventPaymentSetup {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 540px;
  }

  &-eventPreview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    width: 100%;
    @media (min-width: 640px) {
      flex-direction: row;
    }

    &-input {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;
      max-width: 420px;
    }

    &-cardWrapper {
      display: flex;
      flex-grow: 1;
      justify-content: center;
    }

    &-card {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 270px;
      position: relative;
      cursor: pointer;

      .hoverMessage {
        opacity: 0;
        transition: all 0.4s;
      }
      .hoverMessage-hover {
        opacity: 0.75;
      }
      &:hover {
        .hoverMessage {
          opacity: 0.75;
        }
      }
    }

    &-imgWrapper {
      display: flex;
      width: 100%;
      border-radius: 8px;
      position: relative;
      overflow: hidden;

      &-portrait {
        height: 367px;
      }

      &-square {
        height: 270px;
      }

      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: $eventLightOrange;
      }

      &-alternateCta {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        z-index: 2;
        font-size: 18px;
        font-weight: 700;
      }

      &-date {
        height: 4rem;
        width: 4rem;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom-right-radius: 0.5rem;
        top: 0;
        left: 0;
        background-color: $eventNavy;
        color: #ffffff;

        &-1 {
          font-size: 18px;
          font-weight: 500;
        }
        &-2 {
          font-size: 26px;
          font-weight: 800;
          line-height: 1;
        }
      }
    }

    &-details {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-title {
        font-size: 24px;
        font-weight: 800;
      }

      &-venue {
        font-size: 20px;
        font-weight: 500;
      }

      &-time {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  &-calendarShowTimes {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
    height: 100%;

    &-checkBoxItem {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      align-self: flex-end;
      font-size: 14px;
      font-weight: 600;
      color: #73788b;
    }

    &-list {
      display: grid;
      grid-auto-flow: column;
      // flex-wrap: wrap;
      gap: 1.25rem;
      width: 100%;
      height: 100%;
      overflow-x: auto;
      border: 1px solid #73788b;
      padding: 1rem;
      border-radius: 0.5rem;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $neutrals5;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      width: 105px;
    }
  }

  &-calendarShowDays {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 48vh;
    overflow-y: auto;
    width: 100%;
    padding: 0.5rem;
    border-radius: 6px;
    background-color: $neutrals7;

    &-daysList {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      position: relative;
    }

    &-dayHighlightBar {
      position: absolute;
      height: 4px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      align-items: center;
      justify-content: center;
      bottom: 0;
    }

    &-dayHighlight {
      height: 4px;
      width: 70%;
      border-radius: 6px;
      background-color: #3939ff;
      margin: 0 auto;
    }

    &-dayItem {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      transition: all ease 0.05s;
      gap: 0.25rem;
      font-size: 14px;
      font-weight: 600;
      color: #73788b;
      &:hover {
        background-color: rgba(#73788b, 0.1);
      }

      &.evs-calendarShowDays-dayItem-selected {
        color: #3939ff;
        &:hover {
          background-color: rgba(#3939ff, 0.1);
        }
      }
    }

    &-listWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #73788b91;
      border-radius: 0.5rem;
      &.disabled {
        opacity: 0.5;
      }
    }

    &-list {
      display: flex;
      gap: 0.4rem;
      width: 100%;
      flex-wrap: wrap;
      overflow-x: auto;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $neutrals5;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      width: 105px;
    }
  }

  &-calendarShowTimesListing {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    gap: 0.5rem;

    &-item {
      display: flex;
      align-items: center;
      gap: 2rem;
      width: 100%;
      border: 1px solid #73788b;
      padding: 0.5rem;
      border-radius: 0.5rem;
    }

    &-title {
      font-size: 15px;
      font-weight: 500;
      color: #052b5c;
      flex-grow: 1;
    }
  }

  &-previewCalendar {
    display: flex;
    width: 100%;

    &.fixed-height {
      .rbc-calendar {
        height: 100% !important;
        max-height: 600px;
      }

      .rbc-event-content {
        overflow-y: auto;
        height: 48px;
        padding-bottom: 4px;
      }

      .rbc-month-row {
        height: 120px;
        background-color: blue;
      }
      .rbc-month-view {
        border-radius: 12px;
        overflow: auto;
      }
    }

    &-blackoutButton {
      background-color: $blue1;
      color: #ffffff;
      border: none;
      border-radius: 24px;
      cursor: pointer;
      display: flex;
      margin-bottom: 6px;
      padding: 0;
      float: right;
    }

    &-listEvents {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &-listItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px 8px;
      border-radius: 4px;

      &-blackout {
        cursor: default;
        border-left: 4px solid $boomerangRed;
        background-color: #f1606363;
      }
      &-normal {
        border-left: 4px solid #0064ff;
        background-color: #f2f3f5;
      }
      &-special {
        background-color: rgb(254 243 199);
        border-left: 4px solid #ffd000;
      }
    }

    .rbc-calendar {
      width: 100%;
      overflow: auto;

      @media (max-width: 1200px) {
        gap: 8px;
      }
    }

    .rbc-toolbar {
      min-height: 38px;
      justify-content: flex-start !important;
      gap: 4px;

      .rbc-btn-group {
        :hover {
          background-color: #f0f8fe;
          border-color: $blue1;
          color: $blue1;
        }
        :focus {
          background-color: #f0f8fe;
          border-color: $blue1;
          color: $blue1;
        }
        > button:first-child {
          border-radius: 12px 0 0 12px !important;
        }
        > button:last-child {
          border-radius: 0 12px 12px 0 !important;
        }
      }

      &-header {
        position: relative;
        width: 100%;
        display: flex;

        &-rightSection {
          position: absolute;
          right: 0;
          top: -4px;
        }
      }

      @media (max-width: 1200px) {
        flex-direction: column-reverse;
      }
    }

    .rbc-month-view {
      min-width: 680px;
      border-radius: 12px;
      overflow: hidden;
    }

    .rbc-toolbar-label {
      font-size: 20px;
      font-weight: 700;
      font-family: "Ubuntu";
      color: #22272f;

      display: flex;
      justify-content: center;
      max-width: 65%;
    }

    .rbc-header {
      font-size: 15px;
      font-weight: 500;
      color: #052b5c;
      padding: 6px 3px;
    }

    .event-wrapper {
      background-color: transparent;
    }

    .rbc-event {
      background-color: transparent;
      color: #0f151a;
      font-weight: 500;
      width: 100%;
      padding: 10px;
      margin: 6px auto 0;
      min-height: 24px;
    }

    .rbc-selected {
      background-color: transparent !important;
    }

    .rbc-event-content {
      white-space: pre-wrap !important;
    }

    .rbc-month-row {
      flex-basis: auto;

      .rbc-row-bg {
        .rbc-today {
          background-color: #d0fde8 !important;
        }
      }
      .rbc-row-content {
        .rbc-row {
          .rbc-date-cell {
            padding: 7px;
          }
        }
      }
    }
  }

  &-assignTicket {
    display: flex;
    width: 100%;
    overflow-y: auto;
    background-color: $neutrals7;
    border-radius: 6px;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $neutrals5;
    }

    &-header {
      display: flex;
      width: 100%;

      &-box {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 24px;
        margin-left: 4px;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.75rem;
    }

    &-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      border: 1px solid #73788b;
      border-radius: 0.5rem;
      padding: 0.5rem;
      gap: 1rem;

      &-header {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 2rem;

        &-title {
          font-size: 15px;
          font-weight: 500;
          color: #052b5c;
          flex-grow: 1;
          max-width: 150px;
        }

        &-info {
          font-size: 15px;
          font-weight: 500;
          color: #052b5c;
          flex-grow: 1;
        }
      }

      &-ticketsWrapper {
        display: flex;
        width: 100%;
      }

      &-ticketOption {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        padding: 0.5rem;
      }

      &-ticketList {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        padding: 1rem 2rem;
      }

      &-ticketItem {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }

  &-thankYou {
    &-footer {
      display: grid;
      justify-content: center;
      align-items: center;
      align-self: center;
      gap: 1rem;
      max-width: 768px;
      margin-bottom: 1rem;
    }

    &-icon {
      font-size: 5rem !important;
      color: #f9818e;
      margin-bottom: 2rem;
    }
    &-sellIcon {
      font-size: 2.5rem !important;
      color: #f9818e;
    }
    &-manageTicketTypes {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $eventLightOrange;
      border-radius: 0.5rem;
      padding: 14px;
      width: max-content;
      gap: 30px;
    }
  }

  &-overrides {
    &-noOverrides {
      background-color: #f5f5f5;
    }

    &-timeSlot {
      &-inactive {
        background-color: $eventLightOrange;
        padding: 6px 14px;
        border-radius: 7px;
        cursor: pointer;
      }

      &-active {
        background-color: $eventNavy;
        cursor: pointer;
        color: #fff;
        padding: 6px 14px;
        border-radius: 7px;
      }
    }
    &-checkboxGroup {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-left: 11px;
    }
    &-customCheckbox {
      display: flex;
      width: 220px;
      padding: 2px 20px !important;
      border-radius: 8px;
      gap: 6px !important;
      flex-direction: row-reverse;
      font-weight: 700 !important;
      margin: 0 !important;
      justify-content: center;
      cursor: pointer;

      &-active {
        border: 1px solid $eventNavy;
        background-color: $eventLightOrange;
      }
      &-inactive {
        background-color: $eventLightOrange;
      }
      &-option {
        color: #000;
      }
    }
  }

  &-scheduleHeader {
    display: flex;
    flex-direction: column;
    color: $navy;
    margin-bottom: 2rem;

    &-title {
      font-size: 30px;
      font-weight: 700;
      font-family: "Ubuntu";
      line-height: 1.25;
    }

    &-info {
      font-size: 14px;
      font-weight: 600;
      color: $gray1;
      white-space: break-spaces;
    }
  }
  &-max-content {
    width: max-content;
  }
  &-recurring-report {
    &-calender {
      &-show {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fafafa;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #ececec;

        &-details {
          display: flex;
          flex-direction: column;
          gap: 4px;
          &-title {
            font-weight: 500;
            &-past {
              color: #666;
              font-weight: 400;
            }
          }
          &-count {
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
      }

      &-datePicker {
        top: -1rem;
        right: 0;
        position: absolute;
      }

      &-available {
        background: $eventOrange;
      }

      &-sell {
        background: #3939ff;
      }
    }
    &-orderDetails {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 14px;
      min-width: 310px;
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        border-bottom: 1.5px solid #ddd;
        padding: 6px 12px;

        &-left {
          display: flex;
          flex-direction: column;
          gap: 4px;

          &-title {
            font-weight: 600;
            font-size: 16px;
          }
          &-count {
            font-weight: 600;
            font-size: 12px;
            color: #3939ff;
          }
        }
      }
      &-content {
        padding: 0px 12px;

        &-title {
          font-size: 14px;
          font-weight: 600;
        }
        &-count {
          font-size: 14px;
          font-weight: 600;
          color: #3939ff;
        }
        > table > tbody > tr > td {
          padding: 8px 14px;
        }
      }
    }
    &-modalDetails {
      &-status {
        text-transform: uppercase;
        font-size: 14px;
        width: max-content;
      }
    }
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.tabbed {
  width: 100%;
  min-width: 260px;
  margin: 0 auto;
  border-bottom: 4px solid #3939f5;
  overflow: auto;
  transition: border 250ms ease;
}
.tabbed ul {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  float: left;
  padding-left: 48px;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: max-content;
}
.tabbed ul * {
  margin: 0px;
  padding: 0px;
}
.tabbed ul li {
  display: block;
  float: right;
  padding: 10px 24px 8px;
  background-color: #fff;
  margin-right: 46px;
  z-index: 2;
  position: relative;
  cursor: pointer;
  color: #777;
  border-radius: 8px 8px 0 0;

  text-transform: capitalize;
  font: 600 15px/20px roboto, "Open Sans", Helvetica, sans-serif;

  transition: all 250ms ease;
}
.tabbed ul li:before,
.tabbed ul li:after {
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 44px;
  background-color: #fff;
  transition: all 250ms ease;
  border-radius: 8px 8px 0 0;

  @media screen and (max-width: 768px) {
    width: 37px;
  }
}
.tabbed ul li:before {
  right: -24px;
  transform: skew(30deg, 0deg);
  box-shadow: rgba(0, 0, 0, 0.1) 3px 2px 5px, inset rgba(255, 255, 255, 0.09) -1px 0;
  border-radius: 8px 8px 0 0;
}
.tabbed ul li:after {
  left: -24px;
  transform: skew(-30deg, 0deg);
  box-shadow: rgba(0, 0, 0, 0.1) -3px 2px 5px, inset rgba(255, 255, 255, 0.09) 1px 0;
  border-radius: 8px 8px 0 0;
}
.tabbed ul li:hover,
.tabbed ul li:hover:before,
.tabbed ul li:hover:after {
  background-color: #f4f7f9;
  color: #444;
}
.tabbed ul li.active {
  z-index: 3;
}
.tabbed ul li.active,
.tabbed ul li.active:before,
.tabbed ul li.active:after {
  background-color: #3939f5;
  color: #fff;
}

.up-animation {
  animation: moveInBottom 0.5s ease-out 0.5s;
  animation-fill-mode: backwards;
}

.custom-select-input-white-bg {
  .MuiInputBase-root,
  .MuiSelect-root {
    background: #ffffff !important;
  }
}

.custom-select-input {
  .MuiInputBase-root,
  .MuiSelect-root {
    padding: 0 16px;
    background: #f6f7f9;
    border-radius: 48px;
    height: 42px;
    font-weight: 400;

    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    line-height: 21px !important;

    &-error {
      border: 1px solid #f16063 !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &.MuiOutlinedInput-root {
    border-radius: 8px;
    height: 40px;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
  }
  .MuiOutlinedInput-input {
    padding: 0;
  }
  input::placeholder,
  textarea::placeholder {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px !important;
    color: $gray1;
  }
  .Select-control,
  .select__control,
  .css-1s2u09g-control {
    font-family: "Poppins", sans-serif !important;
    background: $gray3;
    border-radius: 8px;
    height: 36px;
    border: 0px;
    .Select-multi-value-wrapper {
      height: 36px;
      .Select-input {
        height: 36px;
        input {
          line-height: 28px;
        }
      }
      .Select-placeholder,
      .Select-value {
        line-height: 36px;
      }
    }
  }
  .css-yk16xz-control,
  .css-1pahdxg-control {
    font-family: "Poppins", sans-serif !important;
    background: $gray3;
    border-radius: 8px;
    border: none;
    height: 36px;
    box-shadow: none;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  &.custom-value-select .MuiInputBase-input {
    font-family: "Poppins", sans-serif !important;
    height: 36px;
    background-color: transparent;
    padding: 0;
  }
  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    display: none;
  }
  .MuiOutlinedInput-multiline {
    padding: 0;
  }
  .MuiIconButton-root {
    padding: 0;
  }
  // .WAMuiChipInput-inputRoot-57.WAMuiChipInput-outlined-60,
  // .WAMuiChipInput-inputRoot-91.WAMuiChipInput-outlined-94 {
  //   padding: 0;
  // }
}

.custom-select-input {
}
