.paper1 {
  padding: 28px 16px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid map_get($gray, lighten-1);
}

.paper-shadow {
  box-shadow: 0px 8px 16px rgba(28, 28, 28, 0.15);
  &-hover:hover {
    transition: box-shadow 350ms ease-in-out;
    box-shadow: 0px 8px 16px rgba(28, 28, 28, 0.15);
  }
  &-hover-green {
    transition: box-shadow 350ms ease-in-out;
    &:hover {
      box-shadow: 0px 0px 4px map_get($green, base);
    }
  }
}

.paper-neu-icon {
  font-size: 28px;
  border-radius: 4px;
}

.paper2 {
  background-color: #ffffff;
  border: 1px solid rgb(232, 234, 237);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 16px 0px;
  margin-bottom: 24px;
}
