/*hotel Module Styles*/

.hotel-module {
  @include flex();

  &:before {
    left: -29px;
  }
}

.hotel-module-box {
  @include box-shadow($shadow-lg);
  @include display-flex(flex, row, nowrap);
  @include flex(1);
  background-color: $gray-100;
  position: relative;
  z-index: 2;
  width: 100%;
}

.hotel-sidenav {
  @include display-flex(flex, column, nowrap);
  @include flex(0, 1, 300px);
  min-width: 300px;
  border-right: solid 1px $gray-200;
}

.hotel-box {
  @include display-flex(flex, column, nowrap);
  @include flex(1);
  max-width: 100%;
}

.hotel-avatar {
  width: 300px;
  height: 200px;
  object-fit: cover;
}

.hotel-sidenav-content {
  background-color: $white;
  position: relative;

  .hotel-side-footer{
    border-top: solid 1px $gray-200;
    background-color: $white;
    width: 100%;
  }
}

.hotel-sidenav-title {
  padding: 10px 16px;
  font-size: $font-size-base;
  color: $info;
  @include display-flex(flex, column, nowrap);
  @include justify-content(center);
}

.hotel-info {
  padding: 16px 16px 16px 16px;
  width: 100% !important;
}
.hotel-date {
  padding: 0 5px;
  text-align: right;
}

.hotel-box-main {
  @include display-flex(flex, column, nowrap);
  @include justify-content(center);
  height: 100%;
}

.module-default {
  @include display-flex(flex, column, nowrap);
  @include justify-content(center);
  @include align-items(center);
  height: 100%;
  padding: 15px;
}


.hotel-main {
  @include display-flex(flex, column, nowrap);
}

.hotel-main-header {
  @include display-flex();
  @include align-items(center);
  border-bottom: solid 1px $gray-200;
  padding: 16px;
  background-color: $white;
  width: 100%;
}

.hotel-main-content {
  @include display-flex();
  @include align-items(center);
  padding: 16px;
  background-color: lighten($gray-100, 0.5%);
  width: 100%;
}
