.event-wrapper {
  background-color: #fff;
  &-selected {
    background-color: #dfdfe9;
  }
  &:hover {
    background-color: #dfdfe9;
  }
  .rbc-event:focus,
  .rbc-day-slot .rbc-background-event:focus {
    outline: none !important;
  }
}

.react-menu-options {
  background-color: #fff;
  color: #000;
  .szh-menu {
    background-color: inherit;
    color: inherit;
    padding: 0.5rem;
    .szh-menu__arrow {
      background-color: inherit;
      color: inherit;
    }
    .szh-menu__item {
      padding: 0.375rem 1rem;
      border-radius: 6px;
      // &--hover {
      //   background-color: $neutrals6;
      // }
    }
  }
}
