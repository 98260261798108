.tenantCard {
  box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  .tenantLink:hover {
    cursor: pointer;
    text-decoration: underline;
    color: map-get($blue, base);
  }
  &:hover {
    transform: scale(1.02);
    box-shadow: 0 0.125rem 0.25rem rgba(57, 57, 255, 0.2);
  }
}
.cross-square-1 {
  position: absolute;
  width: 45vw;
  height: 45vw;
  top: 0;
  left: 0;
  border-radius: 6vw;
  background-color: map-get($primary, lighten-5);
  transform: rotate(45deg) translate(-50%);
}

.cross-square-2 {
  position: absolute;
  width: 45vw;
  height: 45vw;
  bottom: 0;
  right: 0;
  border-radius: 6vw;
  background-color: map-get($primary, lighten-5);
  transform: rotate(45deg) translate(50%);
}

.card-hover {
  box-shadow: 0 0 2px map-get($gray, darken-1);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 1px 4px map-get($gray, darken-2);
  }
}
