.order-info {
  min-height: 100%;
  padding: 20px 30px 30px;
  background-color: #ffffff;
  .icon-custom {
    line-height: 24px;
  }
  .order-header {
    border-bottom: 1px solid #f2f2f7;
  }
  .info-table {
    .info-header {
      min-width: 190px;
      max-width: 190px;
      border-right: 1px solid #edf2f7;

      @media (max-width: 768px) {
        min-width: 165px;
        max-width: 165px;
      }

      @media (max-width: 400px) {
        min-width: 124px;
        max-width: 124px;
      }
    }
    &.info-table-price {
      .info-header {
        min-width: unset;
        max-width: unset;
        flex: 1;
      }
      .info-detail {
        min-width: 180px;
        max-width: 180px;

        @media (max-width: 768px) {
          min-width: 130px;
          max-width: 130px;
        }

        @media (max-width: 400px) {
          min-width: 116px;
          max-width: 116px;
        }
      }
    }
  }
  .order-total-sticky {
    // position: sticky;
    // top: 30px;
  }
}

.order-item-blue {
  background-color: rgba(#43d39e, 0.2) !important; 
}

.order-total-icon {
  font-size: 18px !important;
}

.order-header-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border-bottom: 1px solid #f2f2f7;

  &-equal {
    grid-template-columns: 1fr 1fr;
  }

  .info-detail-grid {
    display: flex;
    width: 100%;
  }
}

.order-header-action {
  transform: rotate(90deg);
  display: flex;
  padding: 4px;
  border: 1px solid rgb(71 85 105);
  border-radius: 4px;
}

$color: #3939ff;
$border: #edf2f7;
$background: #ffffff;

.ordersTabs > ul {
  text-align: center;
  font-weight: 500;
  margin: 50px 0 0;
  padding: 0;
  position: relative;
  border-bottom: 2px solid $border;
  z-index: 1;

  > li {
    display: inline-block;
    background: $background;
    padding: 0.6em 0;
    position: relative;
    width: 25%;
    margin: 0 0 0 -4px;
    cursor: pointer;

    &::before,
    &::after {
      opacity: 0;
      transition: 0.3s ease;
    }

    &.ui-tabs-active::before,
    &.ui-tabs-active::after,
    &.ui-state-hover::before,
    &.ui-state-hover::after,
    &.ui-state-focus::before,
    &.ui-state-focus::after {
      opacity: 1;
    }

    // this is the shadow under the text/mask
    &::before,
    &.ui-state-active.ui-state-hover::before,
    &.ui-state-active.ui-state-focus::before {
      content: "";
      position: absolute;
      z-index: -1;
      box-shadow: 0 1px 2px $color;
      top: 50%;
      bottom: 0px;
      left: 5px;
      right: 5px;
      border-radius: 100px / 10px;
    }

    // this is the "diamond" mask that creates the
    // nice indent effect.
    &::after,
    &.ui-state-active.ui-state-hover::after,
    &.ui-state-active.ui-state-focus::after {
      content: "";
      background: $background;
      position: absolute;
      width: 12px;
      height: 12px;
      left: 50%;
      bottom: -6px;
      margin-left: -6px;
      transform: rotate(45deg);
      box-shadow: inset 2px 2px 2px $color, inset 1px 1px 1px opacify($border, 0.1);
    }
    &.ui-state-hover::before,
    &.ui-state-focus::before {
      box-shadow: 0 2px 3px $border;
    }
    &.ui-state-hover::after,
    &.ui-state-focus::after {
      box-shadow: inset 3px 3px 3px $border, inset 1px 1px 1px opacify($border, 0.1);
    }
    &.ui-state-focus a {
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }

    a {
      color: #444;
      text-decoration: none;

      &:focus {
        outline: none;
      }
      span {
        position: relative;
        top: -0.5em;
      }
    }
  }
}

.ordersTabs {
  width: 99%;
  min-width: 360px;
  margin: auto;
}
