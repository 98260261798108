.pr-container {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 4px;
  gap: 4rem;
  align-items: center;
  justify-items: center;
  color: #052b5c !important;
  font-family: Ubuntu !important;

  &-sub {
    width: 100%;
    max-width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  &-list {
    list-style-type: none;
    gap: 15px;
    li {
      font-size: 18px;
      padding-block: 10px;
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 13px;
      font-weight: 500;
    }
  }
  &-video {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-screen {
      width: 100%;
      background-color: #9f9f9f;
      color: #fff;
      border-radius: 4px;
      height: 260px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
  &-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  &-text-btn {
    border: 0;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    background-color: transparent;
    color: #212529;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
}

.prs {
  &-container {
    font-family: Ubuntu !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: #052b5c !important;
  }
  &-carousel {
    display: flex;
    // max-width: 75%;
    height: 80vh;
    overflow: hidden;
    width: 100%;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    align-items: center;
    // margin-bottom: 20vh;

    ::-webkit-scrollbar {
      width: 4px;
      background-color: $neutrals6;
      border-radius: 16px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #3939ff;
      border-radius: 16px;
    }

    &-options {
      height: 80vh;
    }

    &-header {
      display: flex;
      flex-direction: column;
      color: $navy;
      margin-bottom: 2rem;

      &-title {
        font-size: 32px;
        font-weight: 700;
        font-family: "Ubuntu";
        line-height: 1.25;
      }

      &-info {
        font-size: 14px;
        font-weight: 600;
        color: $gray1;
        white-space: break-spaces;
      }
    }

    &-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;
      overflow-y: auto;
      gap: 3.5rem;
    }

    &-footer {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
    }
    &-light {
      opacity: 0.5;
    }
  }

  &-main-step-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5.5rem;
  }

  &-buttons-container {
    width: 100%;
    padding: 1.5rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border: 1px solid #3939ff;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
  }

  &-icon-size-big {
    font-size: 40px !important;
  }

  &-first-step-buttons {
    padding: 9px 12px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    cursor: pointer;
    color: #fff;
    border: none;
    background-color: #3939ff;
    width: 100%;
    font-family: ubuntu !important;

    &:hover {
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    }
  }

  &-prev-step-buttons {
    padding: 9px 12px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    cursor: pointer;
    color: #73788b;
    background-color: #fff;
    border: 2px solid #f2f2f7;
    border-radius: 10px;
    width: 100%;
    font-family: ubuntu !important;

    &:hover {
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    }
  }

  &-previous-button {
    border-radius: 4px;
    &:hover {
      color: #3939ff;
      background-color: rgba(#3939ff, 0.2);
    }
  }

  &-next-button {
    font-size: 16px;
    width: 10rem;
  }

  &-content-container {
    width: 100%;
    // max-width: 80%;
    padding: 1rem 1.5rem;
    border-radius: 4px;
    border: 1px solid rgb(226, 232, 240);
    background-color: #f9f9fd;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
  }

  &-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-tt-block {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &-tt-block-grid {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
  }

  &-iconWrapper {
    background-color: rgba($blue1, 0.1);
    border-radius: 15px;
    .icon {
      color: $blue1;
      margin: 12px;
    }
  }
  &-check {
    color: #b1b5c3;
  }
  &-checked {
    color: #3939ff;
  }

  &-empty-country-container {
    border: 1px solid #aaaabd;
    padding: 1.5rem 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 75%;
    border-radius: 4px;
  }

  &-country-container {
    display: flex;
    flex-direction: column;
    // gap: 1rem;
    // width: 75%;
  }
  &-country-list-item {
    padding: 1rem;
    border-bottom: 1px solid #aaaabd;
    // width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
  }
  &-remove-link {
    color: #3939ff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &-empty-states-container {
    border: 1px solid #aaaabd;
    padding: 1.5rem 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 4px;
  }

  &-states-container {
    border: 1px solid #aaaabd;
    max-height: 15rem;
    height: 100%;
    overflow-y: auto;
    display: grid;
    width: 100%;
    border-radius: 4px;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding-block: 5px;
  }
  &-state-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 5px 10px;
  }
  &-text-button {
    cursor: pointer;
    color: #3939ff;
    font-size: 13px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.pr-listing {
  &-wrapper-card {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 2rem;
    border-radius: 4px;
    gap: 2rem;
  }
  &-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
  &-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
  }
  &-category-title {
    color: #052b5c;
    font-size: 14px;
    font-weight: 600;
  }
  &-category-value {
    color: #052b5c;
    font-size: 14px;
    font-weight: 400;
  }
  &-category-value-green {
    color: #66cb9f;
    font-size: 14px;
    font-weight: 400;
  }
  &-hover-menu {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 14px;
    min-width: 310px;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      border-bottom: 1.5px solid #ddd;
      padding: 6px 12px;

      &-left {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &-title {
          font-weight: 600;
          font-size: 16px;
        }
        &-count {
          font-weight: 600;
          font-size: 12px;
          color: #3939ff;
        }
      }
    }
    &-content {
      padding: 12px 12px;

      &-title {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        font-size: 16px;
        font-weight: 600;
      }

      &-point {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #3939ff;
        margin-top: 8px;
      }
    }
  }
  &-add-btn {
    border: 0;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    background-color: #3939ff;
    color: #ffffff;
  }
  &-add-btn-green {
    border: 0;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    background-color: #66cb9f;
    color: #ffffff;
  }
  &-all-buyers-mode {
    background-color: rgba(#66cb9f, 0.1);
    padding: 10px 20px;
    border-radius: 4px;
    border: 1px solid #66cb9f;
    color: #052b5c;
  }
}
