.evr {
  &-page {
    display: flex;
    width: 100%;
    margin-bottom: 4rem;
  }
  &-header {
    display: flex;
    align-items: center;
    gap: 1rem;

    &-icon {
      font-size: 30px;
      color: #052b5c;
    }
    &-title {
      font-size: 32px;
      font-weight: 700;
      font-family: "Ubuntu";
      line-height: 1.25;
      color: #052b5c;
    }
    &-subtitle {
      font-size: 14px;
      font-weight: 600;
      color: #73788b;
      white-space: break-spaces;
    }
  }
  &-footer {
    display: flex;
    flex-shrink: 0;
    position: sticky;
    top: 1rem;
  }
  &-wrapper-card {
    display: flex;
    align-items: flex-start;
    box-shadow: 0 0 4px rgba(57, 57, 255, 0.5);
    width: 100%;
    padding: 2rem;
    border-radius: 4px;
    gap: 2rem;
  }
  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
  }
  &-tickets-wrapper {
    display: flex;
    width: 100%;
  }
  &-tickets-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 768px;
    gap: 1.5rem;
  }
  &-ticket {
    &-card {
      display: flex;
      width: 100%;
      gap: 2rem;
      box-shadow: 0 0 4px rgba(57, 57, 255, 0.5);
      border-radius: 0.5rem;
      overflow: hidden;
    }

    &-imgWrapper {
      display: flex;
      height: 100px;
      // width: 100%;
      // max-width: 25%;
      position: relative;
      background-color: #ddd;
    }
    &-img {
      width: 100%;
      object-fit: cover;
    }
    &-imgBadge {
      position: absolute;
      color: #fff;
      padding: 0 4px;
      border-radius: 0 0 4px 0;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      background-color: #052b5c;
    }

    &-details {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      width: 100%;
      padding: 0.5rem 1rem;

      &-header {
        // display: flex;
        // flex-direction: column;
        // align-items: flex-start;
        gap: 0.5rem;
        flex-grow: 1;
      }
    }
    &-title {
      color: #052b5c;
      font-size: 17px;
      font-weight: 600;
    }
    &-price {
      color: #052b5c;
      font-size: 15px;
      font-weight: 500;
    }
    &-inputWrapper {
      flex-shrink: 0;
      &-label {
        color: #052b5c;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  &-blank {
    text-align: center;
    color: #052b5c;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
  }
}
