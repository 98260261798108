$eventNavy: #3e2b59;
$eventOrange: #f68b64;
$eventLightOrange: #fef0ed;

.swt {
  &-wrapper {
    display: flex;
    flex-direction: column;
    // padding: 1rem 2rem;
    gap: 1rem;
    height: 100%;
    background: linear-gradient(135deg, rgb(252 168 215 / 20%), rgb(85 141 253 / 20%));
    overflow: auto;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &-title {
      font-size: 45px;
      font-family: Ubuntu;
      font-weight: 700;
      color: $eventNavy;
    }

    &-close {
      border: 1px solid;
      padding: 4px;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }

  &-contentWrapper {
    // display: flex;
    // flex-direction: row;
    // gap: 5rem;
    width: 100%;
    border-radius: 1.125rem;
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 0 4px rgba($blue1, 0.5);
    // min-height: 80vh;
    overflow: hidden;
    position: relative;

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: $neutrals6;
      border-radius: 16px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #4e376b;
      border-radius: 16px;
    }
  }

  &-sidebar {
    // display: flex;
    // flex-direction: column;
    padding: 1rem;
    position: relative;
    // width: 100%;
    background-color: $backgroundGrey;
    // max-width: 240px;
    border-radius: 24px;
    overflow: hidden;

    &-stepWrapper {
      // display: flex;
      // flex-direction: column;
      transition: all 0.8s ease;
    }

    &-stepWrapper-createFlow {
      height: auto;
      overflow-y: auto;
    }

    &-stepWrapper-editFlow {
      height: 260px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
      }
    }

    &-step {
      display: flex;
      align-items: center;
      color: $eventNavy;
      gap: 1rem;
      padding: 8px;
      transition: all 0.8s ease;
      background-color: $neutrals9;
      border-radius: 16px;
      &.hide-step {
        opacity: 0;
        pointer-events: none;
      }

      &.active-step {
        background-color: $lightBlue;

        &:hover {
          background-color: $lightBlue !important;
        }
        .swt-sidebar-step-number {
          background-color: $pastelBlue;
        }
      }

      &.visited-step {
        background-color: $softMint;

        &:hover {
          background-color: $softMint !important;
        }
        .swt-sidebar-step-number {
          background-color: #00ffa040;
        }
      }

      &.edit-step {
        cursor: pointer;
        border-radius: 0.5rem;

        &:hover {
          background-color: rgba($softMint, 0.2);
        }
      }

      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        background-color: $backgroundGrey;
        border-radius: 0.5rem;
        font-weight: 500;
      }
      &-content {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        line-height: 1.1;
        font-size: 14px;
        font-weight: 500;
      }
      &-stepNumber {
        opacity: 0.7;
      }
    }

    &-seen {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
    }

    &-flag {
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;
      height: 108px;
    }

    &-flag-2 {
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;
      transform: rotateY(180deg);
      height: 54px;
    }

    &-gradient-top {
      position: absolute;
      background: linear-gradient(0deg, $backgroundGrey 61%, transparent);
      bottom: 99px;
      left: 0;
      right: 0;
    }
    &-gradient-bottom {
      position: absolute;
      background: linear-gradient(180deg, #bd608c 20.9%, #442e81 86%);
      bottom: 0;
      left: 0;
      right: 0;
      height: 99px;
    }
  }

  &-carousel {
    display: flex;
    width: 100%;
    height: 75vh;
    overflow: hidden;

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 4px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 75vh;
    flex-shrink: 0;
    margin-bottom: 20vh;
    background-color: $backgroundGrey;
    border-radius: 24px;
    padding: 16px;

    &-header {
      display: flex;
      flex-direction: column;
      color: $navy;
      margin-bottom: 1rem;

      &-title {
        font-size: 32px;
        font-weight: 700;
        font-family: "Ubuntu";
        line-height: 1.25;
      }

      &-small-title {
        font-size: 24px;
        font-weight: 700;
        font-family: "Ubuntu";
        line-height: 1.25;
        color: $navy;
      }

      &-info {
        font-size: 14px;
        font-weight: 600;
        color: $steelBlue;
        white-space: break-spaces;
      }
    }

    &-form {
      display: flex;
      width: 100%;
      flex-grow: 1;
      overflow-y: auto;
      padding: 0 8px;
    }

    &-footer {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      padding: 0.75rem 0.75rem 0 0;
    }
  }

  &-footer-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    background-color: $eventOrange;
    color: #ffffff;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-purple-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    background-color: #4e376b;
    color: #ffffff;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-purple-btn-outlined {
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    border: 1px solid #4e376b;
    color: #4e376b;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-orange-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    background-color: $eventOrange;
    color: #ffffff;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-orange-btn-small {
    border: 0;
    padding: 0.2rem 1rem;
    border-radius: 2rem;
    background-color: $eventOrange;
    color: #ffffff;
    font-weight: 500;
    font-size: 0.9rem;
    @media (min-width: 640px) {
      padding: 0.4rem 0.8rem;
    }
  }

  &-primary-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    background-color: $blue1;
    color: #ffffff;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-text-btn {
    border: 0;
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    background-color: transparent;
    color: $navy;
    font-weight: 500;
  }

  &-outline-btn {
    border: 1px solid $borderDarkGray;
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    background-color: transparent;
    color: $deepGray;
    font-weight: 500;
  }

  &-input {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-label {
      font-size: 14px;
      font-weight: 600;
      color: $navy;
      margin-bottom: 4px;

      &-info {
        font-size: 12px;
        font-weight: 500;
        color: $steelBlue;
        white-space: break-spaces;
      }

      &-big-info {
        font-size: 14px;
        font-weight: 500;
        color: $steelBlue;
        white-space: break-spaces;
      }

      &-big {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: $steelBlue;
        margin-bottom: 4px;
      }

      &-extra-big {
        font-size: 20px;
        font-weight: 600;
        color: $navy;
        margin-bottom: 2px;
      }
    }

    &-error {
      border: 1px solid rgba(#b0222b, 0.5) !important;
      background-color: rgba(#b0222b, 0.05) !important;
    }

    &-input {
      margin: 0;
      padding: 12px;
      border: 1px solid rgba($gray1, 0.5);
      border-radius: 8px;
      outline: none;
    }
    &-helper {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: $steelBlue;
    }

    &-dateTime {
      background-color: #ffffff !important;
      border: 1px solid rgba($gray1, 0.5) !important;
    }
    &-link {
      font-size: 13px;
      font-weight: 600;
      color: $eventNavy;
      text-decoration: underline;
      flex-shrink: 0;
    }
  }

  &-thankYou {
    &-footer {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      align-items: center;
      align-self: center;
      gap: 1rem;
      max-width: 768px;
      margin-bottom: 1rem;
    }

    &-icon {
      font-size: 5rem !important;
      color: $eventNavy;
      margin-bottom: 2rem;
    }
    &-dateTime {
      display: flex;
      gap: 16px;
      width: 324px;
      margin-left: 42px;
    }
  }
}
