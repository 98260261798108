/*Avatar Styles*/
.user-avatar {
  margin-right: 8px;
  @extend %size-50;
  & img {
    max-width: 100%;
    height: auto;
  }
}

.user-detail {
  & .user-name {
    margin-bottom: 2px;
    font-weight: 400;
    text-transform: capitalize;
  }
  & .user-description {
    font-size: 13px;
    margin-bottom: 0;
    color: $gray-600;
  }
}

.userNameAvatar {
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  display: inline-flex;
  flex-shrink: 0;
  user-select: none;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  font-weight: 700;
  text-transform: uppercase;

  &.lg {
    height: 3.5rem;
    width: 3.5rem;
    font-size: 1.25rem;
  }
}
