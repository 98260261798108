.mg {
  &-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    gap: 1rem;
    background-color: rgba($blue1, 0.05);
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &-title {
      font-size: 40px;
      font-family: Ubuntu;
      font-weight: 700;
    }

    &-close {
      border: 1px solid;
      padding: 4px;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }
  &-label-big {
    font-size: 17px;
    font-weight: 600;
    color: $navy;
    margin-bottom: 2px;
  }

  &-selectionWrapper {
    display: flex;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 0 4px rgba($red1, 0.5);
    gap: 12px;

    &-imageBox {
      border: 1px solid rgba($blue1, 0.5);
      border-radius: 8px;
      width: 100%;
      overflow: auto;
      display: inline-grid;
      place-items: center;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        border-radius: 16px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $blue1;
        border-radius: 16px;
      }
    }

    &-imageWrapper {
      width: fit-content;
      padding: 2rem;
    }

    &-image {
      max-height: fit-content;
      max-width: fit-content;
      background-color: #e6e6e6;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      padding: 1rem;
      width: 100%;
    }

    &-inputs {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &-btn {
      border: 0;
      padding: 0.5rem 2rem;
      border-radius: 0.5rem;
      background-color: $navy;
      color: #ffffff;
    }

    &-btn-text {
      border: 0;
      background-color: transparent;
      color: $navy;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  &-outline-btn {
    border: 1px solid $navy;
    padding: 6px 2rem;
    border-radius: 0.5rem;
    background-color: transparent;
    color: $navy;
    font-weight: 500;
  }

  &-peach-btn {
    border: 0;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    background-color: #f9818e;
    color: #ffffff;
    font-weight: 500;
  }

  &-contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #ffffff;
    padding: 1rem;
    margin-bottom: 3rem;
    box-shadow: 0 0 4px rgba($blue1, 0.5);

    &-uploadImage {
      position: relative;
      width: 280px;
      height: 248px;
    }
  }

  &-image {
    &-thumbnail {
      width: 248px;
      height: 248px;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      background-color: #e6e6e6;
      border: 2px solid #e6e6e6;
    }
    &-uploader {
      position: relative;
      width: 248px;
      height: 248px;
      border-radius: 8px;

      &-action {
        position: absolute;
        bottom: 12px;
        display: flex;
        justify-content: center;
        gap: 14px;
        width: 100%;

        &-save {
          background-color: $orange1;
          color: $neutrals9;
          border: none;
          border-radius: 6px;
          padding: 4px 12px;
          font-size: 13px;
        }
        &-cancel {
          background-color: $neutrals9;
          color: $neutrals4;
          border: 1px solid $neutrals5;
          border-radius: 6px;
          padding: 4px 12px;
          font-size: 13px;
        }
      }
    }
    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
