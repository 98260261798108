/*Editor Styles*/
.rdw-inline-wrapper {
  @include display-flex();
}

.rdw-option-wrapper {
  margin-bottom: 6px;
}

.tb-react-quill {
  .ql-toolbar.ql-snow {
    border-color: $neutrals6;
    border-radius: 12px 12px 0 0;
    background-color: $neutrals8;
  }
  .ql-container {
    height: 18rem;
    border-color: $neutrals6 !important;
    border-radius: 0 0 12px 12px;
    background-color: $neutrals9;

    .ql-editor {
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $neutrals5;
        border-radius: 10px;
      }
    }
  }
}

.tb-react-description-quill {
  .ql-container {
    min-height: 18rem;
    .ql-editor {
      height: 18rem;
      min-height: 18rem;
      overflow-y: scroll;
      resize: vertical;
    }
  }
}

.evs-tc-react-quill {
  .ql-container {
    height: 16rem;
  }
}
