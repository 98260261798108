.optionsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sidebarCategory {
  transition: all 0.6s;
}
.showOptions {
  max-height: 500px;
  opacity: 1;
  pointer-events: auto;
}
.hideOptions {
  transform: translateY(-1rem);
  max-height: 0;
  opacity: 0;
  pointer-events: none;
}

.optionsMapping {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  transition: all ease-in-out;
}
