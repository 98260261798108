.organizationPage {
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  &__topHeader {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    padding: 1.5rem;
  }

  &__listWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2.5rem 0.5rem;
    width: 100%;
    // max-width: 1440px;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      &__heading {
        display: flex;
        align-items: center;
        gap: 0.75rem;
      }

      &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.75rem;
      }

      &__action {
        display: flex;
        align-items: center;
        gap: 0.75rem;
      }

      .businessIcon {
        width: 1.5rem;
      }

      .active {
        color: $blue1 !important;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0 1px 2px #cab1b1;

    &__col1 {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: flex-start;
      flex: 1;

      &__name:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      &__website {
        color: inherit;
        &:hover {
          cursor: pointer;
          color: $blue1;
        }
      }
    }

    &__col2 {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      flex: 1;
    }

    &__col3 {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
    }
  }
}
