.orgs {
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    background-color: rgba($blue1, 0.05);
    overflow: auto;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &-title {
      font-size: 45px;
      font-family: Ubuntu;
      font-weight: 700;
    }

    &-close {
      border: 1px solid;
      padding: 4px;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }
  &-contentWrapper {
    width: 100%;
    border-radius: 0.5rem;
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 0 4px rgba($blue1, 0.5);
    overflow: hidden;

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: $neutrals6;
      border-radius: 16px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #4e376b;
      border-radius: 16px;
    }
  }

  &-carousel {
    display: flex;
    width: 100%;
    height: 75vh;
    overflow: hidden;

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      transition: all 1s ease 0s;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 75vh;
    flex-shrink: 0;
    margin-bottom: 20vh;

    &-header {
      display: flex;
      flex-direction: column;
      color: $navy;
      margin-bottom: 2rem;

      &-title {
        font-size: 32px;
        font-weight: 700;
        font-family: "Ubuntu";
        line-height: 1.25;
      }

      &-info {
        font-size: 14px;
        font-weight: 600;
        color: $gray1;
      }
    }
  }

  &-sidebar {
    padding: 1rem;
    position: relative;
    background-color: $blue1;
    border-radius: 8px;
    overflow: hidden;

    &-stepWrapper {
      transition: all 0.8s ease;
    }

    &-step {
      display: flex;
      align-items: center;
      color: #ffffff;
      gap: 1rem;
      padding: 8px;
      transition: all 0.8s ease;
      &.hide-step {
        opacity: 0;
        pointer-events: none;
      }

      &.active-step {
        .orgs-sidebar-step-number {
          background-color: #f9818e;
        }
      }

      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        border: 2px solid;
        border-radius: 1rem;
        font-weight: 500;
      }
      &-content {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        line-height: 1.1;
        font-size: 14px;
        font-weight: 500;
      }
      &-stepNumber {
        opacity: 0.7;
      }
    }

    &-img {
      position: absolute;
      width: 100%;
      bottom: 1rem;
      left: 0;
    }
  }

  &-select {
    width: 100%;

    & > div:first-of-type {
      height: 100%;
      border-radius: 8px;
      border-color: rgba(115, 120, 139, 0.5);
    }

    &.error {
      & > div:first-of-type {
        border: 1px solid rgba(#b0222b, 0.5);
        background-color: rgba(#b0222b, 0.05);
      }
    }
  }

  &-orgInfo {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 540px;
  }

  &-orgAddress {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    max-width: 540px;
    padding-left: 0.25rem;
  }

  &-footer-btn {
    border: 0;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    background-color: #f68b64;
    color: #ffffff;
    height: 100%;
  }

  &-thankYou {
    &-footer {
      display: grid;
      justify-content: center;
      align-items: center;
      align-self: center;
      gap: 1rem;
      max-width: 768px;
      margin-bottom: 1rem;
    }

    &-icon {
      font-size: 5rem !important;
      color: #f9818e;
      margin-bottom: 2rem;
    }
    &-sellIcon {
      font-size: 2.5rem !important;
      color: #f9818e;
    }
    &-manageTicketTypes {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $eventLightOrange;
      border-radius: 0.5rem;
      padding: 14px;
      width: max-content;
      gap: 30px;
    }
  }

  &-link {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
}
