@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins/Poppins-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins/Poppins-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins/Poppins-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins/Poppins-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins/Poppins-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins/Poppins-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../../assets/font/Ubuntu/Ubuntu-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../../assets/font/Ubuntu/Ubuntu-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../../assets/font/Ubuntu/Ubuntu-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "DMSans";
  src: url("../../assets/font/Segoe/DMSans-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "DMSans";
  src: url("../../assets/font/Segoe/DMSans-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "DMSans";
  src: url("../../assets/font/Segoe/DMSans-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "DMSans";
  src: url("../../assets/font/Segoe/DMSans-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "DMSans";
  src: url("../../assets/font/Segoe/DMSans-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "DMSans";
  src: url("../../assets/font/Segoe/DMSans-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "DMSans";
  src: url("../../assets/font/Segoe/DMSans-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "DMSans";
  src: url("../../assets/font/Segoe/DMSans-ExtraBold.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "DMSans";
  src: url("../../assets/font/Segoe/DMSans-Black.ttf");
  font-weight: 1000;
}
// @font-face {
//   font-family: "segoe";
//   src: url("../../assets/font/Segoe/Segoe\ UI\ Bold.woff");
//   font-weight: 700;
// }
// @font-face {
//   font-family: "segoe";
//   src: url("../../assets/font/Segoe/Segoe\ UI\ Bold\ Italic.woff");
//   font-weight: 700;
// }
// @font-face {
//   font-family: "segoe";
//   src: url("../../assets/font/Segoe/Segoe\ UI\ Italic.woff");
//   font-weight: 400;
// }
