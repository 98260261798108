@import "../../../styles/global/color";

.input {
  font-size: 13px;
  font-weight: 400;
  color: #677990
}

.inputError {
  font-size: 12px;
  font-weight: 400;
  color: $accent2;
}

.checkbox {
  font-size: 15px;
  font-weight: 400;
}

.inputHelperLabel {
  font-size: 12px;
  font-weight: 400;
  color: $neutrals5;
}

.inputBig {
  font-size: 17px;
  font-weight: 600;
  color: $navy;
  margin-bottom: 2px;
}
