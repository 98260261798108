.question-list {
  &-name {
    font-size: 16px;
    font-weight: 400;
    color: #334155;
  }

  &-description {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    color: #64748b;
  }
}
