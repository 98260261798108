//
// Basic Bootstrap table
//

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacer;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  .table {
    background-color: $body-bg;
  }
}

//
// Condensed table w/ half padding
//

.table-sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}

// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }

  thead {
    th,
    td {
      border-bottom-width: (2 * $table-border-width);
    }
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: $table-accent-bg;
  }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody tr {
    @include hover {
      background-color: $table-hover-bg;
    }
  }
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

@each $color, $value in $theme-colors {
  @include table-row-variant($color, theme-color-level($color, -9));
}

@include table-row-variant(active, $table-active-bg);

// Dark styles
//
// Same table markup, but inverted color scheme: dark background and light text.

// stylelint-disable-next-line no-duplicate-selectors
.table {
  .thead-dark {
    th {
      color: $table-dark-color;
      background-color: $table-dark-bg;
      border-color: $table-dark-border-color;
    }
  }

  .thead-light {
    th {
      color: $table-head-color;
      background-color: $table-head-bg;
      border-color: $table-border-color;
    }
  }
}

.table-dark {
  color: $table-dark-color;
  background-color: $table-dark-bg;

  th,
  td,
  thead th {
    border-color: $table-dark-border-color;
  }

  &.table-bordered {
    border: 0;
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      background-color: $table-dark-accent-bg;
    }
  }

  &.table-hover {
    tbody tr {
      @include hover {
        background-color: $table-dark-hover-bg;
      }
    }
  }
}

// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

        // Prevent double border on horizontal scroll due to use of `display: block;`
        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}

.tb-table {
  $borderColor: rgb(226 232 240);
  $darkGray: rgb(71 85 105);
  overflow: auto;

  // border: 1px solid $borderColor;
  background-color: white;
  border-radius: 24px;
  padding: 16px;

  &-container {
    border-radius: 0.25rem;
    min-width: max-content;
  }

  &-header {
    display: grid;
    height: 3.5rem;
    gap: 1rem;
    // border-bottom: 1px solid $borderColor;
    padding: 0 1.5rem;

    &-item {
      display: flex;
      align-items: center;
      user-select: none;
      vertical-align: middle;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 600;
      color: $darkGray;
      text-transform: uppercase;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-row {
      display: grid;
      min-height: 3.5rem;
      gap: 1rem;
      // border-bottom: 1px solid $borderColor;
      padding: 0 1.5rem;
      // max-height: 100%;
    }

    &-item {
      display: flex;
      align-items: center;
    }
  }

  &-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: $darkGray;

    &-sm {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
      color: $darkGray;
    }
  }

  &-main-text {
    font-size: 16px;
    font-weight: 400;
    color: rgb(51, 65, 85);
  }

  &-status {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    border-radius: 3rem;
    padding: 0 0.5rem;
    vertical-align: middle;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    height: 22px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;

    &-warning {
      background-color: rgb(254 243 199);
      color: rgb(146 64 14);
    }
    &-info {
      background-color: rgb(241 245 249);
      color: rgb(30 41 59);
    }
    &-success {
      background-color: #1d9b2e1a;
      color: #1d9b2e;
    }
    &-danger {
      background-color: #e241191a;
      color: #e24119;
    }
    &-blue {
      background-color: rgb(219 234 254);
      color: rgb(37 99 235);
    }
    &-gray {
      background-color: rgb(244 245 246);
      color: rgb(37 99 235);
    }
    &-pink {
      background-color: rgb(255 224 244);
      color: rgb(153 27 27);
    }
  }

  &-statusBackground {
    &-warning {
      color: rgb(146 64 14);
      background-color: rgb(254 243 199);
    }
    &-info {
      background-color: rgb(22 130 255);
      color: rgb(219 234 254);
    }
    &-success {
      background-color: #1d9b2e1a;
      color: #1d9b2e;
    }
    &-danger {
      background-color: rgb(153 27 27);
      color: rgb(254 226 226);
    }
    &-blue {
      background-color: rgb(30 41 59);
      color: rgb(219 234 254);
    }
    &-pink {
      background-color: rgb(169 142 255);
      color: rgb(219 234 254);
    }
  }

  &-actionsIcon {
    cursor: pointer;
    color: $darkGray;
  }
}
