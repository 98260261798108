/*Header Styles*/
.jr-menu-icon {
  outline: none !important;
  @include justify-content(flex-start !important);
  @extend %size-30;
  padding: 0 5px !important;
  @extend %jr-menu-icon;
  margin-right: 15px;
}

.app-main-header {
  background-color: $white !important;
  color: #27272e !important;
  box-shadow: none !important;

  //& .search-bar input {
  //  @include transition($transition-base);
  //  background-color: lighten($app-primary, 5%);
  //  color: $white;
  //  & + .search-icon {
  //    top: 0;
  //    & i {
  //      color: rgba($white, 0.8);
  //    }
  //  }
  //
  //  &:focus {
  //    background-color: $white;
  //    color: $body-color;
  //    + .search-icon {
  //      & i {
  //        color: rgba($body-color, 0.8);
  //      }
  //    }
  //  }
  //}
}

.app-logo {
  img {
    height: 50px;
    width: auto;
    border-radius: 10px;

    @media screen and (max-width: 575px) {
      height: 28px;
    }
  }
}

.app-toolbar-special {
  & .search-bar {
    width: 200px;

    @media screen and (max-width: 991px) {
      width: 280px;
    }

    @media screen and (max-width: 767px) {
      width: 200px;
    }
  }
}

.app-toolbar {
  display: flex;
  min-height: $app-bar-height;
  height: $app-bar-height;
  max-height: $app-bar-height;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #27272e;
  border-bottom: 1px solid rgb(235, 236, 240);
  padding: 0 24px;

  & .search-bar {
    margin-left: 100px;
    margin-right: auto;
    max-width: 400px;
    width: 380px;

    @media screen and (max-width: 991px) {
      width: 280px;
    }

    @media screen and (max-width: 767px) {
      margin-left: 40px;
      width: 200px;
    }
  }
}

.text-hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.tenant-header-link {
  display: block;

  @media screen and (max-width: 940px) {
    display: none !important;
  }
}

.app-header-horizontal .app-toolbar {
  & .search-bar {
    margin-left: 20px;
    max-width: 320px;
    width: 300px;

    @media screen and (max-width: 1199px) {
      width: 240px;
      margin-right: 10px;
    }

    @media screen and (max-width: 1099px) {
      width: 200px;
    }

    @media screen and (max-width: 767px) {
      margin-left: 40px;
      width: 180px;
    }
  }
}

.app-toolbar {
  & .nav-searchbox {
    & .search-bar {
      margin-left: 0;
      width: 100%;
    }

    & .search-bar input {
      @include transition($transition-base);
      background-color: $white;
      color: $body-color;
      & + .search-icon {
        & i {
          color: rgba($body-color, 0.8);
        }
      }

      &:focus {
        background-color: transparent;
        color: $body-color;
        + .search-icon {
          & i {
            color: rgba($body-color, 0.8);
          }
        }
      }
    }
  }
}

.header-notifications {
  margin-bottom: 0;

  & li {
    vertical-align: middle;
  }
}

.quick-menu {
  & .dropdown-menu {
    padding: 5px 10px;
    width: 350px;

    & .jr-card-header {
      margin: -5px -10px 5px;
      padding: 10px 10px 10px 20px;
      border-bottom: 1px solid $gray-300;
      z-index: 10;
    }

    &:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $white;
      top: -9px;
      content: "";
      height: 9px;
      position: absolute;
      right: 8px;
      width: 10px;
    }

    &:before {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $black;
      top: -10px;
      content: "";
      height: 10px;
      position: absolute;
      right: 8px;
      width: 10px;
    }

    @media screen and (max-width: 449px) {
      width: 280px;
    }

    @media screen and (max-width: 359px) {
      width: 240px;
    }
  }

  & .messages-list .user-avatar {
    position: relative;
  }

  & .messages-list .badge {
    position: absolute;
    left: -4px;
    top: -4px;
    height: 16px;
    width: 16px;
    line-height: 16px;
    text-align: center;
    padding: 0;
  }
}

@media screen and (max-width: 575px) {
  .nav-searchbox.quick-menu .dropdown-menu {
    width: 200px;
  }
}

@media screen and (max-width: 399px) {
  .nav-searchbox.quick-menu .dropdown-menu {
    width: 100px;
  }
}

.messages-list.language-list ul li {
  padding: 7px 6px 6px;
}

.messages-list ul li {
  position: relative;
  padding: 20px 10px 14px;
  border-bottom: 1px solid $gray-300;

  & .user-name a {
    color: $app-primary;
  }

  & .sub-heading {
    font-size: 11px;
    margin-bottom: 6px !important;

    &.mb-0 {
      margin-bottom: 0 !important;
    }
  }

  &:last-child {
    border-bottom: 0 none;
  }

  & .jr-btn {
    margin-right: 0 !important;
  }

  & .jr-btn-xs {
    font-size: 11px !important;
    padding: 5px !important;
  }
}

.quick-menu {
  & .icon-btn {
    color: $white;
  }

  & .icon-alert {
    position: relative;

    &:after {
      display: block;
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      z-index: 10;
      width: 8px;
      height: 8px;
      background-color: $danger;
      @include border-radius(50%);
    }
  }

  & i.animated.infinite {
    -moz-animation-iteration-count: 25;
    -webkit-animation-iteration-count: 25;
    -o-animation-iteration-count: 25;
    animation-iteration-count: 25;
  }
}

.quick-menu {
  & .dropdown-menu {
    display: block;
    visibility: hidden;
    left: auto !important;
    right: 0 !important;
    opacity: 0;
    transform: translate3d(0px, 100px, 0px) !important;
    @include transition(all 200ms linear 0ms);
  }

  &.show .dropdown-menu {
    opacity: 1;
    transform: translate3d(0px, 30px, 0px) !important;
    visibility: visible;
  }
}

.user-nav .quick-menu {
  & .dropdown-menu {
    width: 200px;
    padding: 0 0 15px;

    &:after {
      border-bottom-color: $body-bg;
    }
  }

  & .user-profile {
    position: relative;
    padding: 20px 25px;
    background-color: $body-bg;
    margin-bottom: 15px;
    @include border-radius(0.25rem 0.25rem 0 0);
    @include display-flex();

    & .user-avatar {
      margin-right: 0;
    }
  }
}

.form-drawer-section-header {
  background-color: $app-primary !important;
  color: $white !important;
  padding: 0.5rem;
}

.btn-outline-drop {
  border: 1px solid #f2f2f7;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  background: #f6f7f9;
}
