@import "../../../styles/global/color";

$sizeArray: (
  "xxs": "12",
  "mxs": "14",
  "xs": "16",
  "sm": "17",
  "md": "18",
  "lg": "19",
  "xl": "20",
  "2xl": "21",
  "3xl": "22",
  "4xl": "24",
  "5xl": "26",
  "6xl": "28",
  "7xl": "30",
  "8xl": "32",
  "9xl": "34",
  "10xl": "36",
  "12xl": "80",
);

@each $name, $size in $sizeArray {
  .size-#{$name} {
    font-size: #{$size} + px;
  }
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 16px;
  background-color: #f6f7f9;

  &:hover {
    border-radius: 16px;
    background-color: #f6f7f9;
  }
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  flex-grow: 0;
  background-color: #f6f7f9;
  border-radius: 50%;
  height: 40px;
  width: 40px;

  &:hover {
    background-color: #f4f4f4;
  }
}
