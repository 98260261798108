$neutrals4: #777e90;

.userInfoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 4px 8px 4px 8px;
  color: #777e90;
  border-radius: 240px;
  background: #f6f7f9;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-grow: 1;

  .userAvatar {
    width: 32px;
    height: 32px;
    background-color: #353945;
    color: #fff;
  }

  .name {
    font-size: 14px;
    font-weight: 600;
    color: #343B46;
  }
}
.logoutIcon {
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #f16063;
  }
}
