@import "../../../styles/global/color";
$height: 42;
$mdHeight: 35;
$smHeight: 30;
$xsHeight: 20;

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.label {
  &.labelError {
    color: $accent2;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputBorder {
  border: 1px solid #eceff2;
}

.input {
  width: 100%;
  padding: 0 16px;
  background: #f6f7f9;
  border-radius: 48px;
  // border-radius: 6px;
  // background: none;
  transition: border-color 0.2s;
  color: $black1;
  // border-color: $neutrals6;

  font-weight: 400;

  &.xs {
    height: $xsHeight + px;
    font-size: 8px;
  }
  &.sm {
    height: $smHeight + px;
    font-size: 12px;
  }

  &.md {
    height: $mdHeight + px;
    font-size: 13px;
  }

  &.lg {
    height: $height + px;
    font-size: 14px;
  }

  &:hover {
    border-color: $neutrals5;
  }
  &:focus {
    border-color: $neutrals4;
  }
  &::placeholder {
    color: $neutrals4;
  }
  &:disabled {
    background-color: $neutrals7;
    pointer-events: none;
  }
  // @include dark {
  //   color: $neutrals8;
  //   border-color: $neutrals3;
  //   &:hover {
  //     border-color: $neutrals4;
  //   }
  //   &:focus {
  //     border-color: $neutrals5;
  //   }
  //   &:disabled {
  //     background-color: $neutrals3;
  //   }
  // }

  &.isError {
    border-color: $accent2;
    &:focus {
      border-color: $accent2;
    }
  }

  &.startComponent {
    &.xs {
      padding-left: 34px;
    }
    &.sm {
      padding-left: 42px;
    }
    &.md {
      padding-left: 54px;
    }
    &.lg {
      padding-left: 64px;
    }
  }

  &.endComponent {
    &.xs {
      padding-right: 18px;
    }
    &.sm {
      padding-right: 24px;
    }
    &.md {
      padding-right: 28px;
    }
    &.lg {
      padding-right: 34px;
    }
  }

  &.searchIcon {
    padding-left: 35px;
  }

  &.startIcon {
    padding-left: 54px;
  }

  &.endIcon {
    padding-right: 38px;
  }

  &.textarea {
    padding: 8px;
    height: auto;
    border-radius: 24px;
  }

  &.passwordComponent {
    padding: 0 40px 0 8px !important;
  }
}

.dropdown {
  position: relative;
  width: 100%;
  &.active {
    .endIcon {
      transform: translate(0, -50%) rotate(180deg);
    }
    .optionContainer {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.optionContainer {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  padding: 8px 0;
  border-radius: 6px;
  visibility: hidden;
  opacity: 0;
  background: $neutrals8;
  transform: translateY(3px);
  transition: all 0.2s;
  box-shadow: inset 0 0 0 2px $neutrals6, 0 4px 12px rgba($neutrals2, 0.1);
  max-height: 300px;
  overflow-y: scroll;
  z-index: 5;
  // @include dark {
  //   background: $neutrals1;
  //   box-shadow: inset 0 0 0 2px $neutrals3, 0 4px 12px rgba($neutrals1, 0.1);
  // }
}

.option {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  transition: color 0.2s;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    color: $blue1;
  }
}

.inputIcon {
  position: absolute;
  &.sm {
    top: calc($smHeight / 2) + px;
  }

  &.md {
    top: calc($mdHeight / 2) + px;
  }

  &.lg {
    top: calc($height / 2) + px;
  }

  transform: translate(0, -50%);
  color: $neutrals2;
  // @include dark {
  //   color: $neutrals8;
  // }
  transition: all 0.2s;
  &.startIcon {
    left: 10px;
    color: $gray1;
  }

  &.endIcon {
    right: 10px;
    color: $gray1;
  }
}

.passwordIcon {
  cursor: pointer;
  user-select: none;
  margin-right: 4px;
}
