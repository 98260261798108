/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
}
.app-wrapper {
  //padding: 12px 24px;
  width: 100%;
  height: 100%;
  // background-color: #fbfbff;
}

.app-backgound {
  background-color: #fbfbff;
}
