@import "metric-tile";
//title black
.title {
  &-48 {
    color: $black1;
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
  }
  &-26 {
    color: $black1;
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
  }
  &-24 {
    color: $black1;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
  }
  &-24-bold {
    color: $black1;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }
  &-22 {
    color: $black1;
    font-size: 22px;
    font-weight: bold;
    line-height: 26px;
  }
  &-extra-22 {
    color: $black1;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
  }
  &-light-22 {
    color: $black1;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
  }
  &-20 {
    color: $black1;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
  &-bold-20 {
    color: $black1;
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
  }
  &-18 {
    color: $black1;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  &-semi-bold-18 {
    color: $black1;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
  }
  &-bold-18 {
    color: $black1;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
  }
  &-light-18 {
    color: $black1;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
  }
  &-17 {
    color: $black1;
    font-size: 17px;
    font-weight: 600;
    line-height: 19px;
  }
  &-16 {
    color: $black1;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
  &-semi-16 {
    color: $black1;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
  }
  &-light-16 {
    color: $black1;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
  &-semi-bold-15 {
    color: $black1;
    font-size: 15px;
    font-weight: 600;
    line-height: 17px;
  }
  &-14 {
    color: $black1;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
  }
  &-normal-14 {
    color: $black1;
    font-size: 14px !important;
    line-height: 16px !important;
    font-weight: 400;
  }
  &-normal-14-600 {
    color: $black1;
    font-size: 14px !important;
    line-height: 16px !important;
    font-weight: 600;
  }
  &-light-14 {
    color: $black1;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
  &-semi-14 {
    color: $black1;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }
  &-12 {
    color: $black1;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
}
//sub title gray
.sub-title {
  &-14 {
    color: $gray1;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
  }
  &-normal-14 {
    color: $gray1;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
  &-medium-14 {
    color: $gray1;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
  &-light-14 {
    color: $gray1;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
  &-light-12 {
    color: $gray1;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
  &-semi-14 {
    color: $gray1;
    font-size: 14px;
    line-height: 22px;
  }
  &-dark-14 {
    color: $gray1;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
  }
  &-13 {
    color: $gray1;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
  }
  &-12 {
    color: $gray1;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }
  &-bold-12 {
    color: $gray1;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600px;
  }
  &-11 {
    color: $gray1;
    font-size: 11px;
    line-height: 13px;
  }
}

.card-title {
  color: $black1;
  text-transform: uppercase;
  font-size: 18px !important;
  line-height: 20px !important;
  font-weight: 600;
  margin-bottom: 0;
}

.font-normal {
  font-weight: 400 !important;
}

.w-15 {
  width: 15% !important;
}

.link-button {
  cursor: pointer;
  color: #3939ff;

  &:hover {
    text-decoration: underline;
  }
}

.font-ubuntu {
  font-family: Ubuntu;
}

$weightArray: (
  "200": 200,
  "300": 300,
  "400": 400,
  "500": 500,
  "600": 600,
  "700": 700,
  "800": 800,
);

.font {
  @each $name, $size in $weightArray {
    &-#{$name} {
      font-weight: #{$size};
    }
  }
}

$fontSizeArray: (
  "10": 0.5,
  "11": 0.625,
  "12": 0.75,
  "13": 0.8125,
  "14": 0.875,
  "15": 0.9375,
  "16": 1,
  "18": 1.125,
  "20": 1.25,
  "22": 1.375,
  "24": 1.5,
  "26": 1.625,
  "28": 1.75,
  "30": 1.875,
  "32": 2,
  "34": 2.125,
  "36": 2.25,
  "40": 2.5,
  "45": 2.875,
  "50": 3.125,
  "56": 3.5,
);

$fontWeightArray: (
  "300": 300,
  "400": 400,
  "500": 500,
  "600": 600,
  "700": 700,
  "800": 800,
  "900": 900,
);

@each $fontName, $fontSize in $fontSizeArray {
  @each $weightName, $weightSize in $fontWeightArray {
    @layer utilities {
      .typo-#{$fontName}-#{$weightName} {
        font-size: #{$fontSize} + rem;
        font-weight: $weightSize;
      }
    }
  }
}
