.signIn-wrapper {
  position: relative;
  // display: grid;
  width: 100vw;
  height: 100dvh;
  // grid-template-columns: repeat(8, 1fr);
  // grid-template-rows: repeat(7, 1fr);
  // grid-gap: 6px;

  img {
    display: block;
    // width: 100%;
    // height: 100%;
    object-fit: cover;
  }

  .image-1 {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
  }

  .image-2 {
    grid-column: 1 / span 2;
    grid-row: 3 / span 3;
  }

  .image-3 {
    grid-column: 1 / span 1;
    grid-row: 6 / span 2;
  }

  .image-4 {
    grid-column: 3 / span 3;
    grid-row: 1 / span 3;
  }

  .image-5 {
    grid-column: 3 / span 2;
    grid-row: 4 / span 2;
  }

  .image-6 {
    grid-column: 8 / span 1;
    grid-row: 3 / span 3;
  }
  .image-7 {
    grid-column: 6 / span 3;
    grid-row: 1 / span 2;
  }

  .image-8 {
    grid-column: 6 / span 2;
    grid-row: 3 / span 2;
  }

  .image-9 {
    grid-column: 5 / span 3;
    grid-row: 5 / span 3;
  }

  .image-10 {
    grid-column: 8 / span 1;
    grid-row: 6 / span 2;
  }

  .image-11 {
    grid-column: 2 / span 3;
    grid-row: 6 / span 2;
  }

  .image-12 {
    grid-column: 5 / span 1;
    grid-row: 4 / span 1;
  }

  .login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 1rem;

    .loginBtn {
      width: 144.36px;
      padding: 0.5rem 3rem;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff !important;
      background-color: #3386dd !important;
      border: none;
      border-radius: 32px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      outline: 0 none !important;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      text-transform: uppercase;

      &:hover {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
}
