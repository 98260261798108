@import "../../styles/global/color";

.modal {
  display: flex;
  position: fixed;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100005;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  @media (min-width: 768px) {
    padding: 1rem;
  }
}

@keyframes showModal {
  0% {
    opacity: 0;
    transform: translateX(-3rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.outer {
  width: 100%;
  max-width: 500px;
  height: 100%;
  border-radius: 20px;
  z-index: 2;
  width: 100%;
  @media (min-width: 768px) {
    opacity: 0;
    animation: showModal 0.5s forwards ease-in-out;
    max-width: 640px;
  }
  background-color: #ffffff;
}

.outerSlidable {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  z-index: 2;
  width: 100%;
  background-color: #ffffff;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.childContainer {
  flex-grow: 1;
  overflow: auto;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid #eceff2;

  .title {
    max-width: 90%;
  }
}

.actionsList {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 8px;
  border-radius: 8px;
  border: 1.5px solid $neutrals4;
  z-index: 100;
  background: #fff;

  .closeIcon {
    color: $blue1;
  }

  .closeText {
    font-size: 12px;
    font-weight: 400;
  }
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}
