/* Globals
   ========================================================================== */
[dir="rtl"] {
  /* Add RTL styles here */
  direction: rtl;
  text-align: right;
}
[dir="ltr"] {
  /* Add LTR styles here */
  direction: lte;
  text-align: left;
}

@import "fonts";
@import "variables";
@import "mixin";
@import "place-holder";
@import "sizes";
@import "color";
@import "custom-animation";
@import "seat";
