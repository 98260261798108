$borderColor: rgb(226 232 240);
$darkGray: rgb(71 85 105);
$mediumGray: rgb(100 116 139);
$lightGray: rgb(51, 65, 85);

.ordersPage {
  display: flex;
  width: 100%;

  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  &-card {
    border-radius: 0.25rem;
    border: 1px solid $borderColor;
    background-color: white;
  }

  &-table {
    &-text {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 500;
      color: $darkGray;
    }

    &-user {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

      &-name {
        font-size: 16px;
        font-weight: 400;
        color: $lightGray;
        cursor: pointer;

        &:hover {
          color: #3b82f6;
          text-decoration: underline;
        }
      }

      &-icon {
        color: $mediumGray;
        font-size: 16px !important;
        margin-right: 0.25rem;
      }

      &-ticketCode {
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 600;
        color: $mediumGray;
      }
    }
  }
}

.map-marker {
  height: 30px;
  width: 30px;
  color: rgb(22 101 52);
  background-color: #fff;
  border-radius: 32px;
  padding: 2px;
}
