/*Tabs Styles*/

.tab {
  min-width: 10px !important;
}

.tab-icon > span {
  @include display-flex();
}
.custom-tab {
  display: flex;
  list-style: none;
  border: 1px solid $green1;
  padding: 0;
  border-radius: 8px;
  margin: 0;
  text-align: center;

  li {
    padding: 9px 20px;
    color: $green1;
    cursor: pointer;
    line-height: initial;
    width: max-content;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }
    &:not(:last-child) {
      border-right: 1px solid $green1;
    }
    a {
      color: $green1;
    }
    &.active {
      background: $green1;
      a {
        color: #ffffff;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 10px 16px;
    }
    @media screen and (max-width: 475px) {
      padding: 7px 8px;
      width: fit-content;
    }
  }
}

.main-custom-tabs {
  .app-bar {
    background-color: $white;
    border-radius: 15px;
    height: 76px;
    box-shadow: none;
    .MuiTabs-root {
      min-height: 76px;
      .MuiTabs-flexContainer {
        min-height: 100%;
        justify-content: space-around;
        button {
          color: $gray1;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          min-width: unset;
          max-width: unset;
          width: 100%;
          flex: 1;
          &.Mui-selected {
            color: $blue1;
            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              height: 4px;
              width: 80%;
              background: #3939ff;
              border-radius: 15px;
            }
          }
          &:not(:last-child)::after {
            content: "";
            background-color: #edf2f7;
            width: 2px;
            height: 56px;
          }
        }
      }
      .MuiTabs-indicator {
        display: none;
      }
    }
  }
}

.tabsContainer {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  border-radius: 15px;
  height: 76px;
  box-shadow: none;
  border-radius: 12px;
  border: 1px solid #e2e7f1;

  .tabContainer {
    width: 100%;
    height: 100%;
    min-height: 76px;

    &:not(:last-child) {
      border-right: 2px solid #edf2f7;
    }

    &:first-child {
      .tab {
        &:hover {
          background-color: rgba($blue1, 0.02);
          border-radius: 12px 0 0 12px !important;
        }
      }
    }

    &:last-child {
      .tab {
        &:hover {
          background-color: rgba($blue1, 0.02);
          border-radius: 0 12px 12px 0 !important;
        }
      }
    }

    .tab {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      align-items: center;
      cursor: pointer;

      color: $gray1;
      font-size: 18px !important;
      font-weight: 600 !important;
      line-height: 20px !important;
      min-width: unset;
      max-width: unset;
      width: 100%;
      flex: 1;

      &:not(:last-child)::after {
        content: "";
        background-color: #edf2f7;
        width: 2px;
        height: 56px;
      }

      &:hover {
        background-color: rgba($blue1, 0.02);
      }
    }
    .activeTab {
      color: $blue1;
      position: relative;

      &:before {
        background: $blue1;
        border-radius: 15px;
        bottom: 0;
        content: "";
        height: 4px;
        position: absolute;
        width: 80%;
      }
    }
  }
}
