/*Alert Styles*/
.alert {
  border: 0 none;
}

.alert {
  padding: 20px 24px;
  border-radius: 8px;
  position: relative;

  & .close {
    line-height: inherit;
    font-weight: $font-weight-light;
  }

  & .alert-addon {
    width: $size-60;
    background-color: rgba($black, 0.2);
    @include display-flex(flex, column, nowrap);
    @include justify-content(center);
    @include align-items(center);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
}

.alert-dismissible {
  padding-right: $size-60;
}

.alert-addon-card {
  padding-left: $size-80;
}

.custom-alert-danger {
  background: rgba($red1,0.08);
  color: $red1;
}
.custom-alert-warning {
  background: rgba($yellow1,0.08);
  color: $yellow1;
}
.custom-alert-blue {
  background: rgba($blue1,0.08);
  color: $blue1;
}
.alert-expansion {
  &.MuiExpansionPanel-root {
    box-shadow: none;
    border-radius: 8px;
    background: rgba($red1,0.08) ;
    color: $red1;
    .MuiExpansionPanelDetails-root{
     border-top:1px solid rgba($red1,0.14);
    }
    .MuiExpansionPanelSummary-expandIcon {
      color: $red1;
    }
  }
}
