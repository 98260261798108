.res-hold-modal {
  &-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    gap: 1rem;
    background-color: rgba($blue1, 0.05);
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &-title {
      font-size: 32px;
      font-family: Ubuntu;
      font-weight: 700;
    }

    &-close {
      border: 1px solid;
      padding: 4px;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }
  &-contentWrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 0 4px rgba($blue1, 0.5);
    overflow: auto;
    flex-grow: 1;
    height: calc(100vh - 104px);

    &-selectedSeatCard {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      width: 100%;
      box-shadow: 0 0 4px rgba($blue1, 0.5);
    }

    &-selectedSeatTitle {
      color: $navy;
      font-size: 14px;
      font-weight: 700;
      font-family: "Ubuntu";
      line-height: 1.25;
    }
  }
}

.holds {
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 0 4px rgba($blue1, 0.5);
    color: $navy;
    border-radius: 1rem;

    &-title {
      font-size: 24px;
      font-weight: 700;
      font-family: "Ubuntu";
      line-height: 1.25;
    }

    &-list {
      display: flex;
      gap: 2rem;
      width: 100%;
      flex-wrap: wrap;
    }
  }

  &-section-card {
    border-radius: 1rem;
    background-color: rgba($blue1, 0.08);
    width: 100%;
    max-width: 375px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    position: relative;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      font-size: 20px;
      font-weight: 700;
      font-family: "Ubuntu";
      line-height: 1.25;
      color: $navy;
    }

    &-previewSvg {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      min-height: 250px;

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        width: 100%;
        height: 280px;
      }
    }

    &-svg {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      min-height: 250px;

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        width: 100%;
        height: 425px;
        max-width: 550px;
        path {
          &.availableSeat {
            cursor: pointer;
          }
          &.selectedSeat {
            fill: #3939ff !important;
            cursor: pointer;
          }
          &.disabledSeat {
            fill: #cacaca !important;
            pointer-events: none;
          }
        }
      }
    }

    &-noSVG {
      font-size: 18px;
      font-weight: 700;
      font-family: "Ubuntu";
      line-height: 1.25;
      color: #f9818e;
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      width: 100%;
    }

    &-outline-btn {
      border: 1px solid $navy;
      padding: 0.5rem;
      border-radius: 0.5rem;
      background-color: rgba($navy, 0.08);
      color: $navy;
      font-weight: 500;
    }

    &-icon {
      font-size: 20px !important;
      color: #f9818e;
      cursor: pointer;
    }
  }

  &-noList {
    &-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      padding: 12px;
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
    }
    &-subtitle {
      font-size: 14px;
      text-align: center;
    }
  }
}
