.adminSetup {
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    background-color: rgba(#ff9f80, 0.05);
    overflow: auto;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &-title {
      font-size: 45px;
      font-family: Ubuntu;
      font-weight: 700;
    }

    &-close {
      border: 1px solid;
      padding: 4px;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }
  &-contentWrapper {
    width: 100%;
    border-radius: 0.5rem;
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 0 4px rgba(#ff9f80, 0.5);
    overflow: hidden;

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: $neutrals6;
      border-radius: 16px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #4e376b;
      border-radius: 16px;
    }
  }

  &-sidebar {
    padding: 1rem;
    position: relative;
    background-color: #ff9f80;
    border-radius: 8px;
    overflow: hidden;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #4e376b;
    }

    &-stepWrapper {
      transition: all 0.8s ease;
    }

    &-step {
      display: flex;
      align-items: center;
      color: #ffffff;
      gap: 1rem;
      padding: 8px;
      transition: all 0.8s ease;
      &.hide-step {
        opacity: 0;
        pointer-events: none;
      }

      &.active-step {
        .adminSetup-sidebar-step-number {
          background-color: $blue1;
        }
      }

      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        border: 2px solid;
        border-radius: 1rem;
        font-weight: 500;
      }
      &-content {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        line-height: 1.1;
        font-size: 14px;
        font-weight: 500;
      }
      &-stepNumber {
        opacity: 0.7;
      }
    }

    &-wave {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
    }

    &-gradient {
      position: absolute;
      background: linear-gradient(0deg, #ff9f80, #ffe5dd, #ffc0b8, transparent);
      bottom: 0;
      left: 0;
      right: 0;
      height: 55vh;
    }
  }

  &-carousel {
    display: flex;
    width: 100%;
    height: 75vh;
    overflow: hidden;

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      transition: all 1s ease 0s;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 75vh;
    flex-shrink: 0;
    margin-bottom: 20vh;

    &-header {
      display: flex;
      flex-direction: column;
      color: $navy;
      margin-bottom: 2rem;

      &-title {
        font-size: 32px;
        font-weight: 700;
        font-family: "Ubuntu";
        line-height: 1.25;
      }

      &-info {
        font-size: 14px;
        font-weight: 600;
        color: $gray1;
      }
    }

    &-form {
      display: flex;
      width: 100%;
      flex-grow: 1;
      overflow-y: auto;
    }

    &-footer {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      padding: 0.75rem 0.75rem 0 0;
    }
  }

  &-footer-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: $blue1;
    color: #ffffff;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-peach-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #ff9f80;
    color: #ffffff;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-text-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: transparent;
    color: $blue1;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-outline-btn {
    border: 1px solid $blue1;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: transparent;
    color: $blue1;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-input {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-label {
      font-size: 14px;
      font-weight: 600;
      color: $navy;
      margin-bottom: 4px;

      &-big {
        font-size: 17px;
        font-weight: 600;
        color: $navy;
        margin-bottom: 2px;
      }

      &-small {
        font-size: 12px;
        font-weight: 600;
        color: $navy;
        margin-bottom: 2px;
      }
    }

    &-error {
      border: 1px solid rgba(#b0222b, 0.5) !important;
      background-color: rgba(#b0222b, 0.05) !important;
    }

    &-input {
      margin: 0;
      padding: 12px;
      border: 1px solid rgba($gray1, 0.5);
      border-radius: 8px;
      outline: none;
    }
    &-helper {
      font-size: 14px;
      font-weight: 500;
      color: $gray1;
    }
  }

  &-thankYou {
    &-footer {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      align-items: center;
      align-self: center;
      gap: 1rem;
      max-width: 768px;
      margin-bottom: 1rem;
    }
    &-icon {
      font-size: 5rem !important;
      color: #ff9f80;
      margin-bottom: 2rem;
    }
  }
}
