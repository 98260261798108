$pink: #ec4c88;

.ccp {
  &-wrapper {
    display: flex;
    flex-direction: column;
    // padding: 1rem 2rem;
    gap: 1rem;
    height: 100%;
    background-color: rgba($blue1, 0.05);
    overflow: auto;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &-title {
      font-size: 45px;
      font-family: Ubuntu;
      font-weight: 700;

      &-sm {
        font-size: 24px;
        font-family: Ubuntu;
        font-weight: 700;
      }
    }

    &-close {
      border: 1px solid;
      padding: 4px;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }

  &-contentWrapper {
    // display: flex;
    // flex-direction: row;
    // gap: 5rem;
    flex-grow: 1;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 0 4px rgba($blue1, 0.5);
    // min-height: 80vh;
    overflow: hidden;

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: $neutrals6;
      border-radius: 16px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #4e376b;
      border-radius: 16px;
    }
  }

  &-sidebar {
    // display: flex;
    // flex-direction: column;
    padding: 1rem;
    position: relative;
    // width: 100%;
    background-color: $navy;
    // max-width: 240px;
    border-radius: 8px;
    overflow: hidden;

    &-stepWrapper {
      // display: flex;
      // flex-direction: column;
      transition: all 0.8s ease;
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #4e376b;
      border-radius: 16px;
    }

    &-step {
      display: flex;
      align-items: center;
      color: #ffffff;
      gap: 1rem;
      padding: 8px 0;
      transition: all 0.8s ease;
      &.hide-step {
        opacity: 0;
        pointer-events: none;
      }

      &.active-step {
        .ccp-sidebar-step-number {
          background-color: $pink;
        }
      }

      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        border: 2px solid;
        border-radius: 1rem;
        font-weight: 500;
      }
      &-content {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        line-height: 1.1;
        font-size: 14px;
        font-weight: 500;
      }
      &-stepNumber {
        opacity: 0.7;
      }
    }

    &-img {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      transform: scale(1.1);
    }

    &-gradient {
      position: absolute;
      background: linear-gradient(0deg, $navy 45%, transparent);
      bottom: 0;
      left: 0;
      right: 0;
      height: 55vh;
    }
  }

  &-input {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-label {
      font-size: 14px;
      font-weight: 600;
      color: $navy;
      margin-bottom: 4px;

      &-big {
        font-size: 17px;
        font-weight: 600;
        color: $navy;
        margin-bottom: 2px;
      }
    }

    &-helper {
      font-size: 14px;
      font-weight: 500;
      color: $gray1;
    }
  }

  &-outline-btn {
    border: 1px solid $navy;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    background-color: transparent;
    color: $navy;
    font-weight: 500;
  }

  &-children {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    min-height: 80vh;
    overflow: hidden;
  }

  &-carousel {
    display: flex;
    width: 100%;
    height: 75vh;
    overflow: hidden;

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      transition: all 1s ease 0s;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 75vh;
    flex-shrink: 0;
    margin-bottom: 20vh;
    position: relative;

    &-header {
      display: flex;
      flex-direction: column;
      color: $navy;
      margin-bottom: 2rem;

      &-title {
        font-size: 32px;
        font-weight: 700;
        font-family: "Ubuntu";
        line-height: 1.25;
      }

      &-info {
        font-size: 14px;
        font-weight: 600;
        color: $gray1;
      }
    }

    &-form {
      display: flex;
      width: 100%;
      flex-grow: 1;
      overflow-y: auto;
    }

    &-footer {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      padding: 0.75rem 0.75rem 0 0;
    }
  }

  &-footer-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: $navy;
    color: #ffffff;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-customRadio {
    width: 220px;
    padding: 7px 20px !important;
    border-radius: 8px;
    gap: 6px !important;
    flex-direction: row-reverse;
    font-weight: 700 !important;
    margin: 0 !important;
    justify-content: center;

    &-active {
      background-color: rgba($blue1, 0.1) !important;
      border: 2px solid $navy !important;
    }
    &-inactive {
      background-color: $gray4 !important;
      border: 2px solid transparent !important;
    }
    &-disable {
      background-color: $gray4 !important;
      border: 1px solid $gray2 !important;
      cursor: not-allowed !important;
      opacity: 0.5;
    }
    &-option {
      color: $pink;
    }
  }

  &-dateTimePicker {
    .custom-input {
      background-color: white !important;
    }
  }

  &-radioGroup {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 11px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &-campaignInfo {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 540px;
  }

  &-campaignRecipients {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 640px;
    height: 390px;
    overflow-y: auto;
    padding: 0 1rem 1rem 2px;

    &-listBox {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 8rem;
    }

    &-list {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      border-radius: 0.5rem;
      border: 1px solid $navy;

      &-content {
        flex-direction: column;
        border-radius: 8px;
        background-color: rgba($navy, 0.05);
        padding: 1rem;
        align-items: center;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
      }

      &-head {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }
    }
  }

  &-campaignMessage {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 640px;
    height: 390px;
    padding: 0 1rem 1rem 0;

    &-fullScreen {
      background-color: $gray4;
      color: $navy;
      border: 1px solid $navy;
      border-radius: 0.5rem;
      padding: 4px 2rem;
      display: flex;
      align-items: center;
      gap: 4px;
      margin-bottom: 8px;
      cursor: pointer;
    }

    &-fullEditor {
      width: 100%;
      .ql-container {
        height: 65vh;
      }
    }
    &-preview {
      background-color: $gray4;
      color: $navy;
      border: 1px solid $navy;
      padding: 7.5px 16px;
      cursor: pointer;
    }
    &-previewEmail {
      display: flex;
      width: 100%;
      overflow-y: auto;
      max-height: 385px;
    }
  }

  &-campaignTestEmail {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    width: 100%;
    max-width: 540px;
  }

  &-campaignPublish {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;

    &-radio {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      gap: 1rem;
      font-weight: 700;

      &-active {
        background-color: rgba($blue1, 0.1);
        border: 2px solid $navy;
      }
      &-inactive {
        background-color: $gray4;
        border: 2px solid transparent;
      }
    }

    &-option {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: $pink;
      cursor: pointer;
      padding: 1rem 2rem;
    }

    &-dateTimePicker {
      display: flex;
      align-items: center;
      padding: 0 2rem 1rem;
      gap: 0.75rem;
    }
  }

  &-thankYou {
    &-footer {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      align-items: center;
      align-self: center;
      gap: 1rem;
      max-width: 768px;
      margin-bottom: 1rem;
    }

    &-icon {
      font-size: 5rem !important;
      color: $pink;
      margin-bottom: 2rem;
    }
    &-dateTime {
      display: flex;
      gap: 16px;
      width: 324px;
      margin-left: 42px;
    }
  }

  &-campaignPreviewEmail {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    max-width: 768px;

    &-img {
      height: 120px;
      align-self: center;
    }

    &-btn {
      color: white;
      font-size: 26px;
      font-family: Source Sans Pro, Arial, Verdana, Tahoma, Geneva, sans-serif, serif, EmojiFont;
      font-weight: 600;
      text-decoration: none;
      line-height: 68px;
      white-space: nowrap;
      background-color: #009ee4;
      width: 310px;
      text-align: center;
      align-self: center;
      cursor: pointer;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
      height: 100%;
      background-color: rgba($blue1, 0.05);
      overflow-y: scroll;
    }

    &-contentWrapper {
      display: flex;
      flex-direction: row;
      gap: 5rem;
      width: 100%;
      border-radius: 0.5rem;
      background-color: #ffffff;
      padding: 1rem;
      box-shadow: 0 0 4px rgba($blue1, 0.5);
      height: 390px;
      overflow: hidden;
    }
  }
}

.campaign-list {
  &-type {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &-icon {
      color: #64748b;
      font-size: 16px !important;
    }

    &-colorIcon {
      font-size: 20px;
      border-radius: 1rem;
      padding: 3px;
    }
  }

  &-name {
    font-size: 16px;
    font-weight: 400;
    color: #334155;
  }

  &-link {
    cursor: pointer;

    &:hover {
      color: #3b82f6;
      text-decoration: underline;
    }
  }

  &-description {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    color: #64748b;
  }

  &-totalCustomers {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    border-radius: 3rem;
    padding: 0;
    vertical-align: middle;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    height: 22px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;
    overflow: hidden;
    padding-right: 0.5rem;

    &-icon {
      font-size: 20px;
      border-radius: 1rem;
      padding: 3px;
    }
  }
}
