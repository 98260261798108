@keyframes moveDownFrame {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}

.moveToDown {
  transition: all 0.4s;
  animation: moveDownFrame 0.4s ease-in-out backwards;
}

.sidebarItems {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
