.ess {
  &-wrapper {
    display: flex;
    flex-direction: column;
    // padding: 1rem 2rem;
    gap: 1rem;
    height: 100%;
    background-color: rgba(#f78b50, 0.05);
    overflow: auto;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &-title {
      font-size: 45px;
      font-family: Ubuntu;
      font-weight: 700;
    }

    &-close {
      border: 1px solid;
      padding: 4px;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }
  &-contentWrapper {
    // display: flex;
    // flex-direction: row;
    // gap: 5rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 0 4px rgba(#f78b50, 0.5);
    // min-height: 80vh;
    overflow: hidden;

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: $neutrals6;
      border-radius: 16px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #6165d7;
      border-radius: 16px;
    }
  }

  &-sidebar {
    // display: flex;
    // flex-direction: column;
    padding: 1rem;
    position: relative;
    // width: 100%;
    background-color: #f78b50;
    // max-width: 240px;
    border-radius: 8px;
    overflow: hidden;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #6165d7;
    }

    &-stepWrapper {
      // display: flex;
      // flex-direction: column;
      transition: all 0.8s ease;
    }

    &-step {
      display: flex;
      align-items: center;
      color: #ffffff;
      gap: 1rem;
      padding: 8px;
      transition: all 0.8s ease;
      &.hide-step {
        opacity: 0;
        pointer-events: none;
      }

      &.active-step {
        .ess-sidebar-step-number {
          background-color: #6165d7;
        }
      }

      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        border: 2px solid;
        border-radius: 1rem;
        font-weight: 500;
      }
      &-content {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        line-height: 1.1;
        font-size: 14px;
        font-weight: 500;
      }
      &-stepNumber {
        opacity: 0.7;
      }
    }

    &-wave {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
    }

    &-gradient {
      position: absolute;
      background: linear-gradient(0deg, #ffc544, #f78b50, transparent);
      bottom: 0;
      left: 0;
      right: 0;
      height: 55vh;
    }
  }

  &-carousel {
    display: flex;
    width: 100%;
    height: 75vh;
    overflow: hidden;

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      transition: all 1s ease 0s;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 75vh;
    flex-shrink: 0;
    margin-bottom: 20vh;

    &-header {
      display: flex;
      flex-direction: column;
      color: $navy;
      margin-bottom: 2rem;

      &-title {
        font-size: 32px;
        font-weight: 700;
        font-family: "Ubuntu";
        line-height: 1.25;
      }

      &-info {
        font-size: 14px;
        font-weight: 600;
        color: $gray1;
      }
    }

    &-form {
      display: flex;
      width: 100%;
      flex-grow: 1;
      overflow-y: auto;
    }

    &-footer {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      padding: 0.75rem 0.75rem 0 0;
    }
  }

  &-footer-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: $navy;
    color: #ffffff;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-peach-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #6165d7;
    color: #ffffff;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-text-btn {
    border: 0;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: transparent;
    color: $navy;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-outline-btn {
    border: 1px solid $navy;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: transparent;
    color: $navy;
    font-weight: 500;
    @media (min-width: 640px) {
      padding: 0.5rem 2rem;
    }
  }

  &-input {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-label {
      font-size: 14px;
      font-weight: 600;
      color: $navy;
      margin-bottom: 4px;

      &-big {
        font-size: 17px;
        font-weight: 600;
        color: $navy;
        margin-bottom: 2px;
      }
    }

    &-error {
      border: 1px solid rgba(#b0222b, 0.5) !important;
      background-color: rgba(#b0222b, 0.05) !important;
    }

    &-input {
      margin: 0;
      padding: 12px;
      border: 1px solid rgba($gray1, 0.5);
      border-radius: 8px;
      outline: none;
    }
    &-helper {
      font-size: 14px;
      font-weight: 500;
      color: $gray1;
    }
  }

  &-attributesPropertyScroll {
    overflow-y: auto;
    max-height: 230px;
    padding: 4px 0;
  }

  &-ticketInfo {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 540px;
  }

  &-ticketDescription {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    max-width: 640px;
  }

  &-ticketPhotos {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 640px;

    &-div {
      display: flex;
      flex-direction: column;
    }
    &-imgPreview {
      position: relative;
      height: 100px;
      border-radius: 4px;
      overflow: hidden;

      &-delete {
        position: absolute;
        color: #6165d7;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          opacity: 1;
        }
      }
    }
    &-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
      border: 1px dashed rgba($gray1, 0.5);
      border-radius: 0.5rem;
      background-color: rgba(#f78b50, 0.05);
      text-decoration: underline;
      cursor: pointer;
    }

    &-imgList {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 0.5rem;
      padding: 0.5rem;
      border: 1px dashed rgba($gray1, 0.5);
      border-radius: 0.5rem;
      background-color: rgba(#f78b50, 0.05);
    }
  }

  &-ticketNftIntro {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 640px;

    &-div {
      display: flex;
      flex-direction: column;
      gap: 40px;
      color: $navy;
    }
    &-heading {
      font-size: 22px;
      color: $navy;
      font-weight: 700;
    }
    &-subHeading {
      font-size: 18px;
      color: $navy;
      font-weight: 600;
    }
    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      svg {
        color: #3939ff;
        font-size: 24px;
      }
    }
  }

  &-ticketPrice {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 540px;

    &-list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      max-width: 400px;

      &-item {
        display: grid;
        grid-template-columns: 1fr 100px;
        gap: 1rem;
      }

      &-title {
        font-size: 14px;
        font-weight: 500;
      }

      &-price {
        font-size: 14px;
        font-weight: 500;
        color: #f78b50;
        text-align: right;
      }
    }
  }

  &-ticketInventory {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 850px;

    &-options {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 1rem;
    }

    &-option {
      display: flex;
      align-items: center;
      padding: 0.6rem 1rem;
      gap: 1rem;
      border-radius: 0.5rem;
      width: 100%;
      border: 1px solid rgba(#f78b50, 0.5);
      background-color: rgba(#f78b50, 0.05);

      &-div {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
      }

      &-title {
        font-size: 14px;
        font-weight: 700;
        color: $navy;
      }
    }
  }

  &-ticketGroups {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 850px;

    &-options {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 1rem;
    }

    &-option {
      display: flex;
      align-items: center;
      padding: 0.6rem 1rem;
      gap: 1rem;
      border-radius: 0.5rem;
      width: 100%;
      border: 1px solid rgba(#f78b50, 0.5);
      background-color: rgba(#fc73b4, 0.05);

      &-div {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
      }

      &-title {
        font-size: 14px;
        font-weight: 700;
        color: $navy;
      }
    }

    &-select {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 150px;
    }
  }

  &-ticketCombo {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 768px;

    &-question {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-info {
      transition: all 1s ease;
      height: 64px;
    }

    &-info-hide {
      height: 0px;
      opacity: 0;
      pointer-events: none;
      z-index: -1;
    }

    &-ticketsList {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;
      margin-bottom: 1rem;
    }

    &-tickets {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      gap: 1rem;
      height: 244px;
      padding: 1rem;
      border-radius: 0.5rem;
      border: 1px solid rgba($gray1, 0.5);
    }

    &-ticketItem {
      display: flex;
      align-items: center;
      gap: 2rem;

      &:last-child {
        margin-bottom: 7rem;
      }

      &-title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: 100%;
      }

      &-quantity {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 144px;
      }

      &-switch {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 225px;
      }
    }

    &-ticketName {
      font-size: 14px;
      font-weight: 500;
    }
  }

  &-ticketAddons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 580px;

    &-question {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-ticketsList {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;
      margin-bottom: 1rem;
    }

    &-tickets {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      gap: 1rem;
      height: 155px;
      padding: 1rem;
      border-radius: 0.5rem;
      border: 1px solid rgba($gray1, 0.5);
    }

    &-ticketItem {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &-ticketName {
      font-size: 14px;
      font-weight: 500;
    }

    &-blankSlate {
      margin-top: 2px;
      min-width: max-content;
    }
  }

  &-ticketAttendee {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    max-width: 700px;

    &-question {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-card {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 14px;
      font-weight: 500;
      border-radius: 0.5rem;
      border: 1px solid rgba($gray1, 0.5);
      padding: 0.5rem;
      background-color: rgba(#fc73b4, 0.05);
    }

    &-options {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 1rem;

      &-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }

      &-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      &-name {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  &-thankYou {
    &-footer {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      align-items: center;
      align-self: center;
      gap: 1rem;
      max-width: 768px;
      margin-bottom: 1rem;
    }
    &-nftPublish {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
    }
    &-nftPublishBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    &-nftPublishQuestion {
      font-size: 26px;
      font-weight: 600;
      color: #052b5c;
      margin-bottom: 2px;
    }
    &-icon {
      font-size: 5rem !important;
      color: #6165d7;
      margin-bottom: 2rem;
    }
  }
}

.setting-header:not(:last-child) {
  border-bottom: 1px solid #eceff2;
}

.setting-info-table {
  border: 1px solid #eceff2;
  border-radius: 10px;
  .setting-info-header {
    min-width: 250px;
    max-width: 250px;
    border-right: 1px solid #eceff2;

    @media (max-width: 768px) {
      min-width: 180px;
      max-width: 180px;
    }

    @media (max-width: 475px) {
      min-width: 160px;
      max-width: 160px;
    }
  }
  &.setting-info-table-price {
    .setting-info-header {
      min-width: unset;
      max-width: unset;
      flex: 1;
    }
    .setting-info-detail {
      min-width: 180px;
      max-width: 180px;
    }
  }
}

.settings-image {
  &-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(76, 111, 255, 0.07);
    border: 1.5px dashed rgba(76, 111, 255, 0.6);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 16px;
    overflow: hidden;
  }
  &-url {
    object-fit: contain;
    background-position: top;
    border-radius: 8px;
  }
}

.settings-email-image {
  &-empty {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(76, 111, 255, 0.07);
    border: 1.5px dashed rgba(76, 111, 255, 0.6);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 16px;
    overflow: hidden;
  }
  &-url {
    object-fit: contain;
    background-position: top;
    border-radius: 8px;
  }
}
