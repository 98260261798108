.custom-login-page{
  width: 100vw;
  height: 100vh;

  .login-box {
    position: absolute;
    height: 100%;
    width: 380px;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    background: rgba(42, 50, 54, 0.8);
    opacity: 1;
    padding: 30px 0px;
    box-shadow: 10px rgba(0,0,0,1);
    text-align: center;
    padding: 35px;
    display: flex;
    align-items: center;
    margin-left: 8%;

    .login-inner-box {
      top: calc(50vh - 200px);
      position: absolute;
    }
    .trypto-logo {
      width: 120px;
      align-self: center;
    }
    h1 {
      text-align: center;
      margin: 30px 0;
      font-size: 30px;
    }
    button {
     background: #2ecc71;
     border: 0;
     color: #fff;
     padding: 10px;
     font-size: 20px;
     width: 300px;
     margin: 0 auto;
     display: block;
     cursor: pointer;
     border-radius: 2px;
     opacity: 0.7;
   }

   button:active, button:hover {
     background: #27ae60;
   }
  }





  /*
    https://codepen.io/magnus16/pen/emNbav
    https://codepen.io/chopfitzroy/pen/JoKMgG

  */
  .carousel-fade .carousel-inner .item.active {
    opacity: 1;
    z-index: 10;
  }

  .carousel-fade .carousel-inner .item:not(.active){
    left: 0;
    opacity: 0;
    z-index: 1;
  }

  .item:nth-child(1) {
    background: url("/assets/images/1.jpeg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .item:nth-child(2) {
    background: url("/assets/images/2.jpeg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .item:nth-child(3) {
    background: url("/assets/images/3.jpeg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .carousel {
    z-index: -99;
  }

  .carousel .item {
      position: fixed;
      width: 100%;
      height: 100%;
  }

}
