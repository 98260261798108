@import "../../../styles/global/color";

.avatar {
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  color: $neutrals8;
  background-color: #bdbdbd;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
