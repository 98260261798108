/*Popover Styles*/
.popover {
  z-index: 1510;
}

.popover-header {
  background-color: transparent;
  border-bottom: 0 none;
  color: $black;
  font-size: $h2-font-size;
  padding: 15px 20px 0;
}

.popover-body {
  padding: 15px 20px;
}

@keyframes custom-popover-slide-frame {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(105%);
  }
}

.custom-popover {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  background-color: map-get($primary, accent-3);
  color: white;
  transform: translateY(105%);
  z-index: 10;
  // animation: custom-popover-slide-frame 0.3s ease 0 backwards;

  .option {
    text-align: center;
    padding: 8px 0;
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  .option:first-of-type {
    border-bottom: 1px solid white;
  }
}
