.nft-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
  border: none;
  outline: none;
  padding: 0.625rem 4rem;
  align-self: center;
  background-color: #f9818e;
  color: white;
  cursor: pointer;
  position: relative;

  &:hover {
    .icon {
      opacity: 1;
      right: 0.5rem;
    }
  }
  .icon {
    opacity: 0;
    position: absolute;
    right: 2rem;
    transition: all 0.3s;
  }
}

.nft-btn-action {
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  padding: 6px 12px;
  border-radius: 10px;
  align-self: center;
  background-color: $green1;
  color: white;
  cursor: pointer;
}

.nft-greenIcon {
  color: map-get($green, base);
}

.nft-redIcon {
  color: $red1 !important;
}

.nft-blueTicket {
  background-color: map-get($blue, lighten-2);
}

.nft-stepper {
  display: flex;
  // flex-direction: column;
  .step {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
    color: $gray1;
    width: 120px;

    .stepIcon {
      font-size: 14px;
    }

    .stepTitle {
      color: currentColor;
    }

    .stepDivider {
      position: absolute;
      display: block;
      height: 2px;
      width: 6.6rem;
      background-color: $gray1;
      bottom: 1.3rem;
      right: -3.3rem;
    }

    &:last-of-type {
      &::before {
        content: none;
      }
    }
  }

  .step-active {
    color: $blue1;
  }

  .step-completed {
    color: $green1;
  }
}

.nft-uploadArtwork {
  .option {
    padding-top: 2rem;
  }

  .ticketListWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .ticketItem {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 1rem;
      width: 175px;
      height: 175px;
      background-color: map-get($blue, lighten-2);
    }
  }
}

.combo-stepper {
  display: flex;
  gap: 12rem;
  // flex-direction: column;
  .step {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
    color: $gray1;

    .stepIcon {
      font-size: 14px;
    }

    .stepTitle {
      color: currentColor;
    }

    .stepDivider {
      position: absolute;
      display: block;
      height: 2px;
      width: 10.6rem;
      background-color: $gray1;
      bottom: 1.5rem;
      right: -11.3rem;
    }

    &:last-of-type {
      &::before {
        content: none;
      }
    }
  }

  .step-active {
    color: $blue1;
  }

  .step-completed {
    color: $green1;
  }
}

.video-stepper {
  display: flex;
  gap: 12rem;
  // flex-direction: column;
  .step {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
    color: $gray1;

    .stepIcon {
      font-size: 14px;
    }

    .stepTitle {
      color: currentColor;
    }

    .stepDivider {
      position: absolute;
      display: block;
      height: 2px;
      width: 10.6rem;
      background-color: #73788b;
      bottom: 1.3rem;
      right: -11.3rem;
    }

    &:last-of-type {
      &::before {
        content: none;
      }
    }
  }

  .step-active {
    color: $blue1;
  }

  .step-completed {
    color: $green1;
  }
}
