@import "../global/color";

.expansion-card {
  &.MuiExpansionPanel-root {
    border-radius: 15px !important;
    box-shadow: none;
    margin-bottom: 0;

    &::before {
      background-color: transparent !important;
    }
  }

  &-summary {
    &.MuiExpansionPanelSummary-root {
      min-height: 65px;
      &.Mui-expanded {
        min-height: 65px;
      }
    }
  }

  &-disabled {
    border-radius: 15px !important;
    box-shadow: none;
    margin-bottom: 0;

    .MuiExpansionPanelSummary-root {
      cursor: default !important;
    }

    background-color: $neutrals8 !important;
  }
}

.combo-expansion-card {
  &.MuiExpansionPanel-root {
    border-radius: 15px !important;
    box-shadow: none;
    margin-bottom: 0;

    &::before {
      background-color: transparent !important;
    }

    .MuiExpansionPanelSummary-content {
      margin: 1.5px;
    }
  }

  &-summary {
    &.MuiExpansionPanelSummary-root {
      min-height: 65px;
      padding: 0 16px 0 0;
      &.Mui-expanded {
        min-height: 65px;
      }
    }
  }
}

.ticket-expansion-card {
  &.MuiExpansionPanel-root {
    border-radius: 15px !important;
    box-shadow: none;
    margin-bottom: 0;

    &::before {
      background-color: transparent !important;
    }
  }

  .expansion-card-summary.MuiExpansionPanelSummary-root {
    min-height: 50px;
  }

  &-summary {
    &.MuiExpansionPanelSummary-root {
      min-height: 50px;
      &.Mui-expanded {
        min-height: 50px;
      }
    }
  }
}

.order-ticket-expansion-card {
  border-radius: 12px !important;
  background-color: #ffffff;
  box-shadow: none;
  margin-bottom: 0;
  border: none !important;

  &::before {
    background-color: transparent !important;
  }

  .expansion-card-summary {
    min-height: 50px;
  }

  &-summary {
    min-height: 50px;
  }
}

.dot-green {
  height: 10px;
  width: 10px;
  background: #66cb9f;
  border-radius: 50%;
}

.dot-red {
  height: 10px;
  width: 10px;
  background: #f16063;
  border-radius: 50%;
}

.preview-container {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 13px 18px;
  background: white;
  min-height: 250px;
}

.sticky-top {
  position: sticky;
  top: 0;
}

.ticket-type-preview-container {
  border-top: 1px solid lightgrey;
  padding: 8px 11px;
}
