/*Badges Styles*/
$badgesArray: (
  "success": #66cb9f,
  "blue": #3939ff,
  "yellow": #fdbe43,
  "danger": #f16063,
);

.badge {
  font-weight: 500;
  font-size: 12px !important;
  line-height: 14px;
  padding: 5px 20px;
  border-radius: 8px;

  @each $name, $color in $badgesArray {
    &-#{$name} {
      color: #{$color};
      background: rgba($color, 0.1);
    }
  }

  &:not(:last-child) {
    margin-right: 15px;
  }

  table & {
    margin-bottom: 0;
  }
}

.badge-pill {
  @include border-radius(20px);
}

.badge-border {
  border: 2px solid !important;
}

.rating-badge {
  color: map_get($yellow, base);
}
