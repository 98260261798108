@import "../../styles/global/color";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  border-color: #e6e8ec;
  padding: 1.5rem;
  border-radius: 24px;
  background-color: #ffffff;
  justify-content: center;
}

.iconWrapper {
  background-color: #f6f8fa;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    color: #3386dd;
    // margin: 12px;
  }
}

.details {
  .title {
  }

  .info {
    font-size: 15px;
    font-weight: 500;
    color: $neutrals4;
  }
}
