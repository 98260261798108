/*Custom Animation*/

@keyframes flipCenter {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: perspective(100000px) rotate3d(1, 0, 0, 90deg);
  }
  40% {
    animation-timing-function: ease-in;
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
  }
  60% {
    opacity: 1;
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
  }
  80% {
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
  }
  100% {
    transform: perspective(100000px);
  }
}
@-webkit-keyframes flipCenter {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: perspective(100000px) rotate3d(1, 0, 0, 90deg);
  }
  40% {
    animation-timing-function: ease-in;
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
  }
  60% {
    opacity: 1;
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
  }
  80% {
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
  }
  100% {
    transform: perspective(100000px);
  }
}
.flipCenter {
  animation-name: flipCenter;
  backface-visibility: visible;
}

@keyframes desployDown {
  0% {
    transform: scaleY(0);
    transform-origin: center top 0;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: center top 0;
  }
}
@-webkit-keyframes desployDown {
  0% {
    transform: scaleY(0);
    transform-origin: center top 0;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: center top 0;
  }
}
.desployDown {
  animation-name: desployDown;
  backface-visibility: visible;
}

@keyframes fadeInRightTiny {
  0% {
    opacity: 0;
    transform: translate3d(30%, 0px, 0px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeInRightTiny {
  0% {
    opacity: 0;
    transform: translate3d(30%, 0px, 0px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRightTiny {
  animation-name: fadeInRightTiny;
}

@keyframes fadeInLeftTiny {
  0% {
    opacity: 0;
    transform: translate3d(-30%, 0px, 0px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeInLeftTiny {
  0% {
    opacity: 0;
    transform: translate3d(-30%, 0px, 0px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeftTiny {
  animation-name: fadeInLeftTiny;
}

@keyframes slideInRightTiny {
  0% {
    transform: translate3d(-20%, 0px, 0px);
  }
  100% {
    transform: none;
  }
}
@-webkit-keyframes slideInRightTiny {
  0% {
    transform: translate3d(-20%, 0px, 0px);
  }
  100% {
    transform: none;
  }
}
.slideInRightTiny {
  animation: slideInRightTiny 0.5s ease-in-out;
}

@keyframes slideInRightMedium {
  0% {
    transform: translate3d(-40%, 0px, 0px);
  }
  100% {
    transform: none;
  }
}
@-webkit-keyframes slideInRightMedium {
  0% {
    transform: translate3d(-40%, 0px, 0px);
  }
  100% {
    transform: none;
  }
}
.slideInRightMedium {
  animation: slideInRightMedium 0.2s ease-in-out;
}

@keyframes slideInLeftTiny {
  0% {
    transform: translate3d(-30%, 0px, 0px);
  }
  100% {
    transform: none;
  }
}
@-webkit-keyframes slideInLeftTiny {
  0% {
    transform: translate3d(-30%, 0px, 0px);
  }
  100% {
    transform: none;
  }
}
.slideInLeftTiny {
  animation-name: slideInLeftTiny;
}

@keyframes slideInUpTiny {
  0% {
    transform: translate3d(0px, 5%, 0px);
  }
  100% {
    transform: none;
  }
}
@-webkit-keyframes slideInUpTiny {
  0% {
    transform: translate3d(0px, 5%, 0px);
  }
  100% {
    transform: none;
  }
}
.slideInUpTiny {
  animation-name: slideInUpTiny;
}

@keyframes slideInDownTiny {
  0% {
    transform: translate3d(0px, -5%, 0px);
  }
  100% {
    transform: none;
  }
}
@-webkit-keyframes slideInDownTiny {
  0% {
    transform: translate3d(0px, -5%, 0px);
  }
  100% {
    transform: none;
  }
}
@keyframes slideUp2rem {
  0% {
    transform: translateY(2rem);
  }
  100% {
    transform: none;
  }
}
@keyframes slideLeft2rem {
  0% {
    transform: translateX(2rem);
  }
  100% {
    transform: translateX(0);
  }
}
.slideUp2remAnimation {
  animation: slideUp2rem 0.8s ease 0s forwards;
}
.slideLeft2remAnimation {
  animation: slideLeft2rem 0.8s ease 0s forwards;
}
.slideInDownTiny {
  animation: slideInDownTiny 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.75s ease-in-out;
}

/*Animation Durations*/
.animation-duration-1 {
  animation-duration: 0.1s;
}

.animation-duration-2 {
  animation-duration: 0.2s;
}

.animation-duration-3 {
  animation-duration: 0.3s;
}

.animation-duration-4 {
  animation-duration: 0.4s;
}

.animation-duration-5 {
  animation-duration: 0.5s;
}

.animation-duration-6 {
  animation-duration: 0.6s;
}

.animation-duration-7 {
  animation-duration: 0.7s;
}

.animation-duration-8 {
  animation-duration: 0.8s;
}

.animation-duration-9 {
  animation-duration: 0.9s;
}

.animation-duration-10 {
  animation-duration: 1s;
}

.animation-duration-11 {
  animation-duration: 1.1s;
}

.animation-duration-12 {
  animation-duration: 1.2s;
}

.animation-duration-13 {
  animation-duration: 1.3s;
}

.animation-duration-14 {
  animation-duration: 1.4s;
}

.animation-duration-15 {
  animation-duration: 1.5s;
}

.animation-duration-16 {
  animation-duration: 1.6s;
}

.animation-duration-17 {
  animation-duration: 1.7s;
}

.animation-duration-18 {
  animation-duration: 1.8s;
}

.animation-duration-19 {
  animation-duration: 1.9s;
}

.animation-duration-20 {
  animation-duration: 2s;
}

.animation-duration-21 {
  animation-duration: 2.1s;
}

.animation-duration-22 {
  animation-duration: 2.2s;
}

.animation-duration-23 {
  animation-duration: 2.3s;
}

.animation-duration-24 {
  animation-duration: 2.4s;
}

.animation-duration-25 {
  animation-duration: 2.5s;
}

.animation-duration-26 {
  animation-duration: 2.6s;
}

.animation-duration-27 {
  animation-duration: 2.7s;
}

.animation-duration-28 {
  animation-duration: 2.8s;
}

.animation-duration-29 {
  animation-duration: 2.9s;
}

.animation-duration-30 {
  animation-duration: 3s;
}

.animation-duration-31 {
  animation-duration: 3.1s;
}

.animation-duration-32 {
  animation-duration: 3.2s;
}

.animation-duration-33 {
  animation-duration: 3.3s;
}

.animation-duration-34 {
  animation-duration: 3.4s;
}

.animation-duration-35 {
  animation-duration: 3.5s;
}

.animation-duration-36 {
  animation-duration: 3.6s;
}

.animation-duration-37 {
  animation-duration: 3.7s;
}

.animation-duration-38 {
  animation-duration: 3.8s;
}

.animation-duration-39 {
  animation-duration: 3.9s;
}

.animation-duration-40 {
  animation-duration: 4s;
}

//Animation Delay

.animation-delay-1 {
  animation-delay: 0.1s;
}
.animation-delay-2 {
  animation-delay: 0.2s;
}
.animation-delay-3 {
  animation-delay: 0.3s;
}
.animation-delay-4 {
  animation-delay: 0.4s;
}
.animation-delay-5 {
  animation-delay: 0.5s;
}
.animation-delay-6 {
  animation-delay: 0.6s;
}
.animation-delay-7 {
  animation-delay: 0.7s;
}
.animation-delay-8 {
  animation-delay: 0.8s;
}
.animation-delay-9 {
  animation-delay: 0.9s;
}
.animation-delay-10 {
  animation-delay: 1s;
}
.animation-delay-11 {
  animation-delay: 1.1s;
}
.animation-delay-12 {
  animation-delay: 1.2s;
}
.animation-delay-13 {
  animation-delay: 1.3s;
}
.animation-delay-14 {
  animation-delay: 1.4s;
}
.animation-delay-15 {
  animation-delay: 1.5s;
}
.animation-delay-16 {
  animation-delay: 1.6s;
}
.animation-delay-17 {
  animation-delay: 1.7s;
}
.animation-delay-18 {
  animation-delay: 1.8s;
}
.animation-delay-19 {
  animation-delay: 1.9s;
}
.animation-delay-20 {
  animation-delay: 2s;
}
.animation-delay-21 {
  animation-delay: 2.1s;
}
.animation-delay-22 {
  animation-delay: 2.2s;
}
.animation-delay-23 {
  animation-delay: 2.3s;
}
.animation-delay-24 {
  animation-delay: 2.4s;
}
.animation-delay-25 {
  animation-delay: 2.5s;
}
.animation-delay-26 {
  animation-delay: 2.6s;
}
.animation-delay-27 {
  animation-delay: 2.6s;
}
.animation-delay-28 {
  animation-delay: 2.8s;
}
.animation-delay-29 {
  animation-delay: 2.9s;
}
.animation-delay-30 {
  animation-delay: 3s;
}
.animation-delay-31 {
  animation-delay: 3.1s;
}
.animation-delay-32 {
  animation-delay: 3.2s;
}
.animation-delay-33 {
  animation-delay: 3.3s;
}
.animation-delay-34 {
  animation-delay: 3.4s;
}
.animation-delay-35 {
  animation-delay: 3.5s;
}
.animation-delay-36 {
  animation-delay: 3.6s;
}
.animation-delay-37 {
  animation-delay: 3.7s;
}
.animation-delay-38 {
  animation-delay: 3.8s;
}
.animation-delay-39 {
  animation-delay: 3.9s;
}
.animation-delay-40 {
  animation-delay: 4s;
}
.animation-delay-41 {
  animation-delay: 4.1s;
}
.animation-delay-42 {
  animation-delay: 4.2s;
}
.animation-delay-43 {
  animation-delay: 4.3s;
}
.animation-delay-44 {
  animation-delay: 4.4s;
}
.animation-delay-45 {
  animation-delay: 4.5s;
}
.animation-delay-46 {
  animation-delay: 4.6s;
}
.animation-delay-47 {
  animation-delay: 4.7s;
}
.animation-delay-48 {
  animation-delay: 4.8s;
}
.animation-delay-49 {
  animation-delay: 4.9s;
}
.animation-delay-50 {
  animation-delay: 5s;
}
